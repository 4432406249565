@layer base {
  :root[data-energy-save="true"] {
    /* background */
    --pb-background-default: var(--pb-palette-green-90);
    --pb-background-brand: var(--pb-palette-green-70);
    --pb-background-subtle: var(--pb-palette-green-70);
    /* text */
    --pb-text-default: var(--pb-palette-white);
    --pb-text-subtle: var(--pb-palette-green-30);
    --pb-text-brand: var(--pb-palette-white);

    /* Link */
    --pb-text-link: var(--pb-palette-white);
    --pb-text-link-hover: var(--pb-palette-white);
    --pb-text-link-focus: var(--pb-palette-sea-blue-60);
    --pb-text-link-disabled: var(--pb-palette-green-60);

    /* icon */
    --pb-icon-default: var(--pb-palette-white);
    --pb-icon-brand: var(--pb-palette-white);
    --pb-logo-yellow: var(--pb-palette-white);

    /* border */
    --pb-border-default: var(--pb-palette-grey-50);
    --pb-border-disabled: var(--pb-palette-grey-40);
    --pb-border-focus: var(--pb-palette-sea-blue-60);

    /* button (primary) */
    --pb-button-primary: var(--pb-palette-green-60);
    --pb-button-primary-border: var(--pb-button-primary);
    --pb-button-primary-text: var(--pb-palette-white);
    --pb-button-primary-icon: var(--pb-button-primary-text);

    --pb-button-primary-hover: var(--pb-palette-green-50);
    --pb-button-primary-border-hover: var(--pb-button-primary-hover);
    --pb-button-primary-text-hover: var(--pb-palette-white);
    --pb-button-primary-icon-hover: var(--pb-button-primary-text-hover);

    --pb-button-primary-active: var(--pb-palette-green-80);
    --pb-button-primary-border-active: var(--pb-button-primary-active);
    --pb-button-primary-text-active: var(--pb-palette-white);
    --pb-button-primary-icon-active: var(--pb-button-primary-text-active);

    --pb-button-primary-disabled: var(--pb-palette-green-80);
    --pb-button-primary-border-disabled: var(--pb-button-primary-active);
    --pb-button-primary-text-disabled: var(--pb-text-disabled);
    --pb-button-primary-icon-disabled: var(--pb-button-primary-text-disabled);

    /* button (primary brand) */
    --pb-button-primary-brand: var(--pb-palette-green-70);
    --pb-button-primary-brand-border: var(--pb-button-primary-brand);
    --pb-button-primary-brand-text: var(--pb-palette-white);
    --pb-button-primary-brand-icon: var(--pb-button-primary-brand-text);

    --pb-button-primary-brand-hover: var(--pb-palette-green-50);
    --pb-button-primary-brand-border-hover: var(--pb-button-primary-brand-hover);
    --pb-button-primary-brand-text-hover: var(--pb-palette-white);
    --pb-button-primary-brand-icon-hover: var(--pb-button-primary-brand-text-hover);

    --pb-button-primary-brand-active: var(--pb-palette-green-80);
    --pb-button-primary-brand-border-active: var(--pb-button-primary-brand-active);
    --pb-button-primary-brand-text-active: var(--pb-palette-white);
    --pb-button-primary-brand-icon-active: var(--pb-button-primary-brand-text-active);

    --pb-button-primary-brand-disabled: var(--pb-palette-green-80);
    --pb-button-primary-brand-border-disabled: var(--pb-button-primary-brand-active);
    --pb-button-primary-brand-text-disabled: var(--pb-text-disabled);
    --pb-button-primary-brand-icon-disabled: var(--pb-button-primary-brand-text-disabled);

    /* button (esg) */
    --pb-button-primary-esg: var(--pb-palette-green-70);
    --pb-button-primary-esg-border: var(--pb-button-primary-esg);
    --pb-button-primary-esg-text: var(--pb-palette-white);
    --pb-button-primary-esg-icon: var(--pb-button-primary-esg-text);

    --pb-button-primary-esg-hover: var(--pb-palette-green-60);
    --pb-button-primary-esg-border-hover: var(--pb-button-primary-esg-hover);
    --pb-button-primary-esg-text-hover: var(--pb-palette-white);
    --pb-button-primary-esg-icon-hover: var(--pb-button-primary-esg-text-hover);

    /* button (secondary) */
    --pb-button-secondary: var(--pb-background-default);
    --pb-button-secondary-border: var(--pb-palette-green-50);
    --pb-button-secondary-text: var(--pb-palette-white);
    --pb-button-secondary-icon: var(--pb-button-secondary-text);

    --pb-button-secondary-hover: var(--pb-palette-green-80);
    --pb-button-secondary-border-hover: var(--pb-palette-green-50);
    --pb-button-secondary-text-hover: var(--pb-palette-white);
    --pb-button-secondary-icon-hover: var(--pb-button-secondary-text-hover);

    --pb-button-secondary-active: var(--pb-palette-green-70);
    --pb-button-secondary-border-active: var(--pb-palette-green-50);
    --pb-button-secondary-text-active: var(--pb-palette-white);
    --pb-button-secondary-icon-active: var(--pb-button-secondary-text-active);

    --pb-button-secondary-disabled: var(--pb-background-default);
    --pb-button-secondary-border-disabled: var(--pb-border-disabled);
    --pb-button-secondary-text-disabled: var(--pb-text-disabled);
    --pb-button-secondary-icon-disabled: var(--pb-button-secondary-text-disabled);

    /* button (transparent) */
    --pb-button-transparent: var(--pb-background-default);
    --pb-button-transparent-border: var(--pb-button-transparent);
    --pb-button-transparent-text: var(--pb-palette-white);
    --pb-button-transparent-icon: var(--pb-button-transparent-text);

    --pb-button-transparent-hover: var(--pb-palette-green-80);
    --pb-button-transparent-border-hover: var(--pb-palette-green-80);
    --pb-button-transparent-text-hover: var(--pb-palette-white);
    --pb-button-transparent-icon-hover: var(--pb-button-transparent-text-hover);

    --pb-button-transparent-active: var(--pb-palette-green-70);
    --pb-button-transparent-border-active: var(--pb-palette-green-70);
    --pb-button-transparent-text-active: var(--pb-palette-white);
    --pb-button-transparent-icon-active: var(--pb-button-transparent-text-active);

    --pb-button-transparent-disabled: var(--pb-background-default);
    --pb-button-transparent-border-disabled: var(--pb-button-transparent-disabled);
    --pb-button-transparent-text-disabled: var(--pb-text-disabled);
    --pb-button-transparent-icon-disabled: var(--pb-button-transparent-text-disabled);

    /* card */
    /** featured cards **/
    --pb-featured-card-default: var(--pb-background-default);
    --pb-featured-card-default-border: var(--pb-border-default);

    --pb-featured-card-brand: var(--pb-background-default);
    --pb-featured-card-brand-border: var(--pb-border-default);

    --pb-featured-card-green: var(--pb-background-default);
    --pb-featured-card-green-border: var(--pb-border-default);

    /** Product cards **/
    --pb-product-card-default: var(--pb-palette-green-70);

    --pb-product-card-green: var(--pb-palette-green-70);

    --pb-product-card-bottom: var(--pb-background-default);
    --pb-product-card-bottom-divider: var(--pb-palette-green-70);

    /* Card Promo */
    --pb-card-promo-main-title: var(--pb-palette-white);

    /* Document Cards */
    --pb-m-document-cards-background: var(--pb-palette-green-80);
    --pb-card-document-background-default: var(--pb-palette-green-80);
    --pb-card-document-border-default: var(--pb-palette-green-60);
    --pb-card-document-label-default: var(--pb-palette-green-30);
    --pb-card-document-svg-desktop: var(--pb-palette-white);
    --pb-card-document-svg-desktop--hover: var(--pb-palette-yellow-60);

    /* Tag */
    --pb-c-tag-default: var(--pb-palette-white);
    --pb-c-tag-background-default: var(--pb-palette-white);
    --pb-c-tag-border-default: var(--pb-palette-white);

    /* form-elements */
    --pb-c-input-normal: var(--pb-palette-white);
    --pb-c-input-background-normal: var(--pb-palette-green-90);
    --pb-c-input-border-normal: var(--pb-palette-green-60);
    --pb-c-input-hover: var(--pb-palette-green-70);
    --pb-c-input-background-hover: var(--pb-palette-white);
    --pb-c-input-border-hover: var(--pb-palette-green-60);
    --pb-c-input-checked: var(--pb-palette-white);
    --pb-c-input-active: var(--pb-palette-green-70);
    --pb-c-input-background-active: var(--pb-palette-green-50);
    --pb-c-input-border-active: var(--pb-palette-green-50);
    /* --pb-c-input-focus: var(--pb-palette-green-110); */
    --pb-c-input-border-focus: var(--pb-palette-sea-blue-60);
    --pb-c-input-disabled: var(--pb-palette-green-50);
    --pb-c-input-background-disabled: var(--pb-palette-grey-50);
    --pb-c-input-border-disabled: var(--pb-palette-green-50);
    --pb-c-input-error: var(--pb-palette-red-20);
    --pb-c-input-border-error: var(--pb-palette-red-20);
    --pb-c-input-option-hover: var(--pb-palette-warm-grey-10);
    --pb-c-input-search-icon: var(--pb-palette-white);
    /* Checkbox and Radio */
    --pb-c-input-btn-normal: var(--pb-palette-green-70);
    --pb-c-input-btn-background-normal: var(--pb-palette-white);
    /* Datepicker */
    --pc-c-datepicker-day-selected: var(--pb-palette-white);
    --pc-c-datepicker-day-hover: var(--pb-palette-green-60);
    // Two toggle switch
    --pb-c-two-toggle-bg: var(--pb-palette-green-90);
    --pb-c-two-toggle-switch: var(--pb-palette-green-60);
    --pb-c-two-toggle-switch-esg: var(--pb-palette-green-60);

    // Drop File
    --pb-form-file-border: var(--pb-palette-green-60);
    --pb-form-file-border--hover: var(--pb-palette-sea-blue-60);
    --pb-form-file-border--focus: var(--pb-palette-sea-blue-60);
    --pb-form-file-border--error: var(--pb-palette-red-40);
    --pb-form-file-background: var(--pb-palette-green-90);
    --pb-form-file-background--hover: var(--pb-palette-green-70);
    --pb-form-file-background--focus: var(--pb-palette-green-50);
    --pb-form-file-uploaded: var(--pb-palette-green-70);
    
    /* header-module */
    --pb-m-header-background: var(--pb-palette-green-70);
    --pb-m-header-text: var(--pb-palette-white);
    --pb-m-header-button-background: var(--pb-palette-yellow-60);
    --pb-m-header-button-text: var(--pb-palette-green-70);
    --pb-m-header-level-2-background: var(--pb-palette-green-60);
    --pb-m-header-level-2-text: var(--pb-palette-white);

    /* block-action-module */
    --pb-m-block-action-title: var(--pb-text-default);
    --pb-m-block-action-background-color: var(--pb-background-default);
    --pb-m-block-info-border-color-single: var(--pb-palette-white);
    /* TODO they havent finished the energy mode yet */

    /* block-info-module */
    --pb-m-block-info-text-default: var(--pb-text-default);
    --pb-m-block-info-background-color: var(--pb-palette-green-70);
    /* TODO they havent finished the energy mode yet */

    /* TODO they havent finished the energy mode yet */

    /* banner-text-only */
    --pb-c-banner-text-only-background-color: var(--pb-background-default);
    --pb-c-banner-text-only-title: var(--pb-text-default);
    --pb-c-banner-text-only-text: var(--pb-text-default);

    /* Banner-video */
    --pb-m-banner-video-background-grey: var(--pb-palette-green-70);
    --pb-m-banner-video-background-green: var(--pb-palette-green-70);

      /* hero-slide-component */
    --pb-c-hero-slide-bubble: var(--pb-palette-green-60);
    --pb-c-hero-slide-border: var(--pb-palette-green-60);

    /* hero-component */
    --pb-c-hero-text: var(--pb-text-default);
    --pb-c-hero-background: var(--pb-background-default);

    --pb-c-hero-landing-illustration-background: var(--pb-palette-green-90);

    /* hero-Article */
    --pb-c-hero-article-text:var(--pb-palette-green-90);
    --pb-c-hero-article-text-yellow:var(--pb-palette-green-90);
    --pb-c-hero-article-text-warm-grey: var(--pb-palette-green-90);
    --pb-c-hero-article-text-green: var(--pb-palette-green-90);
    /* hero-internal */
    --pb-c-hero-internal-white-background: var(--pb-background-default);
    --pb-c-hero-internal-yellow-background: var(--pb-palette-green-70);
    --pb-c-hero-internal-green-background: var(--pb-palette-green-70);

    /* block-action */
    --pb-c-block-action-background-default: var(--pb-background-default);
    --pb-c-block-action-text-default: var(--pb-palette-white);
    --pb-c-block-action-text-description: var(--pb-palette-white);
    --pb-c-block-action-hover-background-default: var(--pb-palette-green-60);
    --pb-c-block-action-border: var(--pb-palette-green-50);

    /* block-info */
    --pb-c-block-info-background-default: var(--pb-palette-green-90);
    --pb-c-block-info-background-white: var(--pb-palette-green-70);
    --pb-c-block-info-background-hover: var(--pb-palette-green-60);
    --pb-c-block-info-icon-green: var(--pb-palette-white);
    --pb-c-block-info-icon-green-hover: var(--pb-palette-white);
    --pb-c-block-info-icon: var(--pb-palette-white);
    --pb-c-block-info-icon-hover: var(--pb-palette-white);

    /* block-cta */
    --pb-c-block-cta-background-default: var(--pb-background-default);
    /* to do (transparent)*/
    --pb-c-block-cta-text-description: var(--pb-text-default);
    --pb-c-block-cta-border: var(--pb-palette-green-50);

    /* breadcrumb */
    --pb-c-breadcrumb-text-li: var(--pb-palette-white);
    --pb-c-breadcrumb-text-last-child: var(--pb-palette-green-30);
    --pb-c-breadcrumb-text-last-child-underline-hover: var(--pb-palette-green-30);

    /* Quick Link */

    --pb-c-quick-link-text: var(--pb-palette-white);
    --pb-c-quick-link-background-default: var(--pb-palette-green-80);
    --pb-c-quick-link-background-hover: var(--pb-palette-green-70);
    --pb-c-quick-link-background-active: var(--pb-palette-green-80);
    --pb-c-quick-link-border: var(--pb-c-quick-link-background-default);
    --pb-c-quick-link-border-hover: var(--pb-c-quick-link-background-hover);
    --pb-c-quick-link-border-active: var(--pb-c-quick-link-background-active);

    /* Filter-Item */
    --pb-c-filter-item: var(--pb-palette-green-70);
    --pb-c-filter-item-documents: var(--pb-palette-green-70);
    --pb-c-filter-item-text: var(--pb-palette-white);
    --pb-c-filter-item-icon: var(--pb-c-filter-item-text);

    --pb-c-filter-item-hover: var(--pb-palette-green-60);
    --pb-c-filter-item-text-hover: var(--pb-palette-white);
    --pb-c-filter-item-icon-hover: var(--pb-c-filter-item-text-hover);

    --pb-c-filter-item-active: var(--pb-palette-white);
    --pb-c-filter-item-text-active: var(--pb-palette-green-60);
    --pb-c-filter-item-icon-active: var(--pb-c-filter-item-text-active);

    --pb-c-filter-item-disabled: var(--pb-palette-green-80);
    --pb-c-filter-item-text-disabled: var(--pb-palette-green-60);
    --pb-c-filter-item-icon-disabled: var(--pb-c-filter-item-text-disabled);

    --pb-c-filter-item-green: var(--pb-palette-green-70);
    --pb-c-filter-item-green-text: var(--pb-palette-white);
    
    --pb-c-filter-item-green-hover: var(--pb-palette-green-60);
    --pb-c-filter-item-green-text-hover: var(--pb-palette-white);

    --pb-c-filter-item-green-active: var(--pb-palette-white);
    --pb-c-filter-item-green-text-active: var(--pb-palette-green-60);

    --pb-c-filter-item-green-disabled: var(--pb-palette-green-80);
    --pb-c-filter-item-green-text-disabled: var(--pb-text-green-60);

    /* Filter Module */
    --pb-m-filter-title: var(--pb-palette-white);

    /* Accordion */
    --pb-c-accordion-text: var(--pb-palette-white);
    --pb-c-accordion-border: var(--pb-palette-green-60);
    --pb-c-accordion-text--hover: var(--pb-palette-green-30);
    --pb-c-accordion-border--hover: var(--pb-palette-green-50);

    /* footer */
    --pb-m-footer-background: var(--pb-palette-green-80);
    --pb-m-footer-background-contact: var(--pb-palette-green-80);
    --pb-m-footer-background-social: var(--pb-palette-green-80);
    --pb-m-footer-text-subtle: var(--pb-palette-green-10);
    --pb-m-footer-icon-subtle: var(--pb-palette-green-20);
    --pb-m-footer-text: var(--pb-palette-white);
    --pb-m-footer-divider: var(--pb-palette-green-60);

    /* tabs */
    --pb-m-tabs-hover-border: var(--pb-palette-white);

    /* help */
    --pb-m-help-bubble-background-color: var(--pb-palette-green-60);
    --pb-m-help-bubble-background-business: var(--pb-palette-green-60);
    --pb-m-help-bubble-title-color: var(--pb-palette-white);
    --pb-m-help-bubble-text-color: var(--pb-palette-white);

    /* HightLights */
    --pb-m-hightlight-background: var(--pb-palette-green-70);
    --pb-m-hightlight-background-link: var(--pb-palette-green-60);
    --pb-c-hightlight-border: var(--pb-palette-green-60);

    /* Banner Image */
    --pb-c-banner-image-title: var(--pb-palette-white);

    /* Banner-Marketing */
    --pb-m-banner-marketing-background: var(--pb-palette-green-80);

    /* Banner-conversion */
    --pb-c-banner-conversion-background: var(--pb-background-default);
    --pb-c-banner-conversion-border: var(--pb-palette-green-50);

    /* Banner 4 Items Center*/
    --pb-m-banner-4items-center-background: var(--pb-palette-green-60);
    --pb-m-banner-4items-border: var(--pb-palette-green-60);
    /* to be reviewed */
    --pb-m-banner-4items-center-link: var(--pb-palette-white);

    /* Step */
    --pb-c-step-background: var(--pb-palette-green-70);
    --pb-c-step-item-background: var(--pb-palette-green-90);

    /* Blog */
    --pb-m-blog: var(--pb-palette-green-70);
    --pb-c-blog-card-date: var(--pb-palette-green-30);
    --pb-c-blog-card-bg: var(--pb-palette-green-70);
    --pb-c-blog-card-border: var(--pb-palette-green-60);

    /* Article */
    --pb-c-article-tags: var(--pb-palette-green-30);
    --pb-c-article-icon: var(--pb-palette-white);
    --pb-m-article-horizontal-bg: var(--pb-palette-green-80);

    /* Swiper */
    --pb-swiper-pagination-bullet: var(--pb-palette-white);
    --pb-swiper-pagination-bullet-border: var(--pb-swiper-pagination-bullet);
    --pb-swiper-pagination-bullet-inverse: var(--pb-palette-white);
    --pb-swiper-pagination-bullet-border-inverse: var(--pb-swiper-pagination-bullet-inverse);

    /* Branches & Map */
    --pb-c-branch-address-background: var(--pb-palette-green-70);
    --pb-c-branch-link: var(--pb-text-default);
    --pb-c-map-info-window: var(--pb-palette-green-70);
    --pb-c-map-info-window-border: var(--pb-c-map-info-window);
    --pb-info-window-border-outer: var(--pb-palette-warm-grey-60);

    /* Choices*/
    --pb-choices-highlight : var(--pb-palette-green-60);

    /* Menu */
    --pb-m-menu-sector-background--active: var(--pb-palette-white), 0.7;
    --pb-m-menu-sector-text--active: var(--pb-palette-green-70);
    --pb-m-menu-text--color: var(--pb-palette-white);
    --pb-m-menu-background: var(--pb-palette-green-60);
    --pb-m-menu-background-mobile: var(--pb-palette-green-70);
    --pb-m-menu-toggle-color: var(--pb-palette-white);
    --pb-m-menu-border-subtle: var(--pb-palette-green-60);
    --pb-m-menu-icon-color : var(--pb-palette-white);
    --pb-m-menu-yellow-logo-btn : var(--pb-palette-green-70);
    --pb-m-menu-main-logo-color : var(--pb-palette-white);
    --pb-background-inner-content : var(--pb-background-default);
    --pb-text-color-inner-content : var(--pb-m-menu-text--color);

    // Blog Tag
      --pb-blog-tag-text--color : var(--pb-palette-white);

    //ToolTip
    --pb-c-tool-tip-bg : var(--pb-palette-green-110);
    --pb-c-tool-tip-txt: var(--pb-palette-white);

    // ContactForm
    --pb-contact-form-modal-bg : var(--pb-palette-green-80);
    --pb-form-bg : var(--pb-palette-green-80);
    --pb-form-bubble-bg : var(--pb-palette-green-70);
    --pb-form-bubble-border : var(--pb-palette-green-80);

    // Complaint form 
    --pb-addition-contact-bg : var(--pb-palette-green-70);

    //Time radio button
    --pb-time-radio-background :  var(--pb-palette-green-80);
    --pb-time-radio-border :  var(--pb-palette-white);
    --pb-time-radio-background--checked :  var(--pb-palette-white);
    --pb-time-radio-border--checked :  var(--pb-palette-white);
    --pb-time-radio-text--checked :  var(--pb-palette-green-60);
    

    //Progress Bar
      --pb-progress-bar-bg : var(--pb-palette-green-90);
      --pb-progress-bar-active : var(--pb-palette-white);
    
    //Chatty
    --pb-c-chatty-bg : var(--pb-palette-green-80);
    --pb-c-chatty-container-logo: var(--pb-palette-green-70);
    --pb-c-chatty-logo: var(--pb-palette-white);
    --pb-c-chatty-bubble-left : var(--pb-palette-green-70);
    --pb-c-chatty-bubble-left-border : var(--pb-palette-green-80);
    --pb-c-chatty-bubble-right : var(--pb-palette-green-60);
    --pb-c-chatty-bubble-right-border : var(--pb-palette-green-80);

    //ebanking modal
    --pb-ebanking-modal-footer-bg : var(--pb-palette-green-80);
    --pb-ebanking-modal-footer-text-color : var(--pb-palette-white);

    //knowledge hub search 
    --pb-m-knowledgehub-search-bg:var(--pb-palette-green-70);

    // Search
    --pb-c-search-bg:var(--pb-palette-white);
  }
}

@layer components {
  html[data-energy-save="true"] {
    img {
      display: none;
    }
  }
}