.pb-m-video{

	.pb-video-inner-title-container{
		
		@screen xl{
			color: rgba(var(--pb-c-video-text),1);
		}
	}

	.plyr.plyr--paused {

		.plyr__video-wrapper{
			filter: brightness(0.4);
		}

		button.plyr__control--overlaid{
			
			@screen lg{
				left: 9.5rem;
				top: auto;
				bottom: 48px;
			}
		}
	}
}


.plyr.plyr--paused {

	&.plyr--stopped .plyr__controls {
		display: none;
	}

	button.plyr__control--overlaid {
		width: 60px;
		height: 60px;

		@screen xl{
			width: 80px;
			height: 80px;
		}

		&:hover {
			background: none;
		}

		&:before {
			content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNy42NzQxIDI0Ljc2NDNDMzMuNzQyMiAyMi43NzUgMjkuMTI1MSAyNS42NTg0IDI5LjEyNTEgMzAuMDY1NlY0OS45MzNDMjkuMTI1MSA1NC41ODk3IDM0LjIyNTcgNTcuNDQ2OCAzOC4xNjMyIDU0Ljk1NjdMNTUuNjE1NyA0My45MTkyQzU5LjUwMzQgNDEuNDYwNSA1OS4yMzEyIDM1LjY3MSA1NS4xMjY2IDMzLjU5NDNMMzcuNjc0MSAyNC43NjQzWk0zMy4zODY5IDMwLjA2NTZDMzMuMzg2OSAyOC44MjUyIDM0LjY3NyAyOC4wNCAzNS43NTY0IDI4LjU4NjFMNTMuMjA4OSAzNy40MTZDNTQuMzUwMyAzNy45OTM1IDU0LjQyODUgMzkuNjEyNSA1My4zNDQ1IDQwLjI5ODFMMzUuODkxOSA1MS4zMzU2QzM0LjgxMSA1Mi4wMTkyIDMzLjM4NjkgNTEuMjQzOSAzMy4zODY5IDQ5LjkzM1YzMC4wNjU2WiIgZmlsbD0id2hpdGUiLz4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQwIDc1QzU5LjMzIDc1IDc1IDU5LjMzIDc1IDQwQzc1IDIwLjY3IDU5LjMzIDUgNDAgNUMyMC42NyA1IDUgMjAuNjcgNSA0MEM1IDU5LjMzIDIwLjY3IDc1IDQwIDc1Wk00MCA3MEM1Ni41Njg1IDcwIDcwIDU2LjU2ODUgNzAgNDBDNzAgMjMuNDMxNSA1Ni41Njg1IDEwIDQwIDEwQzIzLjQzMTUgMTAgMTAgMjMuNDMxNSAxMCA0MEMxMCA1Ni41Njg1IDIzLjQzMTUgNzAgNDAgNzBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=);
			width: 100%;
			height: 100%;
			position: absolute;
			left:0px;
			top:0px;
		}

		svg{
			display: none;
		}
		
	}
}


.iframe-container{
	position: relative;
    height: 0;
    padding-bottom: 56%;

	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

:root[data-sector="private"] {

	.pb-m-video .plyr.plyr--paused button.plyr__control--overlaid:before{
		content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNy42NzQxIDI0Ljc2NDNDMzMuNzQyMiAyMi43NzUgMjkuMTI1MSAyNS42NTg0IDI5LjEyNTEgMzAuMDY1NlY0OS45MzNDMjkuMTI1MSA1NC41ODk3IDM0LjIyNTcgNTcuNDQ2OCAzOC4xNjMyIDU0Ljk1NjdMNTUuNjE1NyA0My45MTkyQzU5LjUwMzQgNDEuNDYwNSA1OS4yMzEyIDM1LjY3MSA1NS4xMjY2IDMzLjU5NDNMMzcuNjc0MSAyNC43NjQzWk0zMy4zODY5IDMwLjA2NTZDMzMuMzg2OSAyOC44MjUyIDM0LjY3NyAyOC4wNCAzNS43NTY0IDI4LjU4NjFMNTMuMjA4OSAzNy40MTZDNTQuMzUwMyAzNy45OTM1IDU0LjQyODUgMzkuNjEyNSA1My4zNDQ1IDQwLjI5ODFMMzUuODkxOSA1MS4zMzU2QzM0LjgxMSA1Mi4wMTkyIDMzLjM4NjkgNTEuMjQzOSAzMy4zODY5IDQ5LjkzM1YzMC4wNjU2WiIgZmlsbD0iI0NBQzNBRiIvPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDAgNzVDNTkuMzMgNzUgNzUgNTkuMzMgNzUgNDBDNzUgMjAuNjcgNTkuMzMgNSA0MCA1QzIwLjY3IDUgNSAyMC42NyA1IDQwQzUgNTkuMzMgMjAuNjcgNzUgNDAgNzVaTTQwIDcwQzU2LjU2ODUgNzAgNzAgNTYuNTY4NSA3MCA0MEM3MCAyMy40MzE1IDU2LjU2ODUgMTAgNDAgMTBDMjMuNDMxNSAxMCAxMCAyMy40MzE1IDEwIDQwQzEwIDU2LjU2ODUgMjMuNDMxNSA3MCA0MCA3MFoiIGZpbGw9IiNDQUMzQUYiLz4KPC9zdmc+);
	}

	.pb-m-banner-video .plyr.plyr--paused button.plyr__control--overlaid:before{
		content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNy42NzQxIDI0Ljc2NDNDMzMuNzQyMiAyMi43NzUgMjkuMTI1MSAyNS42NTg0IDI5LjEyNTEgMzAuMDY1NlY0OS45MzNDMjkuMTI1MSA1NC41ODk3IDM0LjIyNTcgNTcuNDQ2OCAzOC4xNjMyIDU0Ljk1NjdMNTUuNjE1NyA0My45MTkyQzU5LjUwMzQgNDEuNDYwNSA1OS4yMzEyIDM1LjY3MSA1NS4xMjY2IDMzLjU5NDNMMzcuNjc0MSAyNC43NjQzWk0zMy4zODY5IDMwLjA2NTZDMzMuMzg2OSAyOC44MjUyIDM0LjY3NyAyOC4wNCAzNS43NTY0IDI4LjU4NjFMNTMuMjA4OSAzNy40MTZDNTQuMzUwMyAzNy45OTM1IDU0LjQyODUgMzkuNjEyNSA1My4zNDQ1IDQwLjI5ODFMMzUuODkxOSA1MS4zMzU2QzM0LjgxMSA1Mi4wMTkyIDMzLjM4NjkgNTEuMjQzOSAzMy4zODY5IDQ5LjkzM1YzMC4wNjU2WiIgZmlsbD0iIzFFMUUxRSIvPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDAgNzVDNTkuMzMgNzUgNzUgNTkuMzMgNzUgNDBDNzUgMjAuNjcgNTkuMzMgNSA0MCA1QzIwLjY3IDUgNSAyMC42NyA1IDQwQzUgNTkuMzMgMjAuNjcgNzUgNDAgNzVaTTQwIDcwQzU2LjU2ODUgNzAgNzAgNTYuNTY4NSA3MCA0MEM3MCAyMy40MzE1IDU2LjU2ODUgMTAgNDAgMTBDMjMuNDMxNSAxMCAxMCAyMy40MzE1IDEwIDQwQzEwIDU2LjU2ODUgMjMuNDMxNSA3MCA0MCA3MFoiIGZpbGw9IiMxRTFFMUUiLz4KPC9zdmc+);
	}
}





