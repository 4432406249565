
.pb-calculator{

    background-color: rgba(var(--pb-palette-warm-grey-10), 1);

    .pb-calc-right-side{
        background-color: rgba(var(--pb-palette-yellow-60), 1);
    }
    .pb-calc-bubble{
        // if it needs the same colors with the forms ,then copy paste
       background-color: rgba(var(--pb-palette-white), 1);
    }

    .pb-calc-maxi-bubble{
        background-color: rgba(var(--pb-palette-yellow-60), 1);
     }

    .pb-calc-maxi-bubble.pb-bubble-esg{
        background-color: rgba(var(--pb-palette-olive-green-40), 1);
     }

    .pb-calc-bubble-img{
        background-color: rgba(var(--pb-palette-yellow-60), 1);
     }

    .pb-calc-maxi-bubble:before {
        @apply pb-border-b-[24px] pb-border-l-[14px] lg:pb-border-b-[40px] lg:pb-border-l-[22px];
        content: "";
        /* border-left-width: 6px; */
        border-right-width: 0px;
        /* border-bottom-width: 15px; */
        border-color: transparent;
        border-bottom-color: rgba(var(--pb-palette-warm-grey-10), 1);
        height: 0;
        left: 5px;
        bottom: -1px;
        right: -6px;
        position: absolute;
        overflow: hidden;
    }

    .pb-calc-bubble:before {
        @apply pb-border-b-[24px] pb-border-l-[14px] lg:pb-border-b-[40px] lg:pb-border-l-[22px];
        content: "";
        /* border-left-width: 6px; */
        border-right-width: 0px;
        /* border-bottom-width: 15px; */
        border-color: transparent;
        border-bottom-color: rgba(var(--pb-palette-yellow-60), 1);
        height: 0;
        left: 5px;
        bottom: -1px;
        right: -6px;
        position: absolute;
        overflow: hidden;
    }

    .swiper-maxi-calculator-bullet{

        .swiper-pagination-bullet{
            @apply pb-text-sm-m lg:pb-text-lg pb-font-bold pb-text-warm-grey-60 pb-border-none pb-bg-transparent;
        }

        .swiper-pagination-bullet-active{
            @apply pb-text-green-70 pb-bg-transparent;
        }
    }

    .pb-calc-bubble-img:after{
        content: "";
        position: absolute;
        width: 32px;
        height: 0;
        bottom: -32px;
        left: -2px;
        z-index: 2;
        border-color: transparent;
        border-bottom-color: rgba(var(--pb-palette-yellow-60), 1);
        border-bottom-width: 33px;
        border-left-width: 22px;
        border-right-width: 0px;
        transform: rotate(180deg);
    }

    .pb-calc-tax-right-side{
        @apply pb-bg-white lg:pb-bg-yellow-60;
    
        .pb-calc-tax-right-side-card{
            @apply pb-bg-white;
        }
    }

}

html[data-energy-save="true"] {

    .pb-calculator {
        background-color: rgba(var(--pb-palette-green-70), 1);

        .pb-calc-right-side{
            background-color: rgba(var(--pb-palette-green-80), 1);
        }

        .pb-calc-bubble{
            background-color: rgba(var(--pb-palette-green-90), 1);
        }

        .pb-calc-maxi-bubble, .pb-calc-maxi-bubble.pb-bubble-esg{
            background-color: rgba(var(--pb-palette-green-70), 1);
         }

        .pb-calc-maxi-bubble:before {
            border-bottom-color: rgba(var(--pb-palette-green-70), 1);
        }

        .pb-calc-bubble:before {
            border-bottom-color: rgba(var(--pb-palette-green-80), 1);
        }

        .pb-calc-bubble-img{
            background-color: rgba(var(--pb-palette-green-70), 1);
        }
        .pb-calc-bubble-img:after{
            border-bottom-color: rgba(var(--pb-palette-green-70), 1);
        }
        .pb-calc-tax-right-side{
            background-color: rgba(var(--pb-palette-green-80), 1);
        
            .pb-calc-tax-right-side-card{
                @apply pb-bg-green-80 lg:pb-bg-green-90;
            }
        }

        .pb-btn-primary-esg, .pb-btn-primary-brand{
            background-color: rgba(var(--pb-palette-green-60), 1);
        }
    }
}