.pb-c-tabs {

	ul{
		scrollbar-width: none;
	}

	ul>li {

		/* &.pb-tab--active {
			@apply pb-pt-1;
		} */

		div {
			display: inline;
			position: relative;
			overflow: hidden;
			text-wrap: nowrap;
		}

		div:after {
			content: "";
			position: absolute;
			z-index: -1;
			right: 0;
			width: 0;
			bottom: -5px;
			background: rgba(var(--pb-m-tabs-hover-border), 1);;
			height: 4px;
			transition-property: width;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
		}

		&:hover div:after,
		&.pb-tab--active div:after{
			left: 0;
			right: auto;
			width: 100%;
		}


		&.pb-tab--active div {
			@apply pb-font-bold;

		}

		
	}
/* 

	.pb-tab-content {
		

		.pb-tab-pane {
			@apply pb-hidden pb-w-full;

			&.pb-tab-pane--active {
				@apply pb-block;
			}
		}
	} */

}


/* .underline {
    display: inline;
    position: relative;
    overflow: hidden;
  }
  .underline:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    width: 0;
    bottom: -5px;
    background: #000;
    height: 4px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .underline:hover:after,
  .underline:focus:after,
  .underline:active:after {
    left: 0;
    right: auto;
    width: 100%;
  } */