.swiper.pb-swiper-fm,
.pb-swiper-fm {
  padding-left: theme('container.padding.DEFAULT');
  padding-right: 0px;

  @screen sm {
    padding-left: /* calc((100% - theme('screens.sm')) / 2 + */ theme('container.padding.sm');
  }
  
  @screen md {
    padding-left: /* calc((100% - theme('screens.md')) / 2 + */ theme('container.padding.md');
  }

  @screen lg {
    padding-left: /* calc((100% - theme('screens.lg')) / 2 + */ theme('container.padding.lg');
  }

  @screen xl {
    padding-left: /* calc((100% - theme('screens.xl')) / 2 + */ theme('container.padding.xl');
    @media (min-width: 1445px){
      padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.xl"));
    }
  }

  // @screen 2xl {
  //   @media (min-width: 1731px){
  //     padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.2xl"));
  // }
  //   padding-left: /* calc((100% - theme('screens.2xl')) / 2 + */ theme('container.padding.2xl');
  // }

  // @screen 3xl {
  //   padding-left: calc((100vw - 1444px) / 2);
  // }  
}
.swiper.pb-mobile-swiper-fm,
.pb-mobile-swiper-fm{
  padding-left: theme('container.padding.DEFAULT');
  padding-right: 0px;

  @screen sm {
    padding-left: /* calc((100% - theme('screens.sm')) / 2 + */ theme('container.padding.sm');
  }
  
  @screen md {
    padding-left: /* calc((100% - theme('screens.md')) / 2 + */ theme('container.padding.md');
  }

  @screen lg {
    @apply pb-container;
  } 
  
}

.swiper .swiper-pagination.pb-swiper-pagination-left{
text-align: left;
padding-left: theme('container.padding.DEFAULT');
padding-right: 0px;

@screen sm {
  padding-left: theme('container.padding.sm');
}

@screen md {
  padding-left: theme('container.padding.md');
}

@screen lg {
  bottom: 10%;
  padding-left:  theme('container.padding.lg');

}

@screen xl {
  // padding-left: calc((100% - theme('screens.xl')) / 2 + theme('container.padding.xl'));
  padding-left: theme("container.padding.xl");
  @media (min-width: 1445px){
    padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.xl"));
  }
}

// @screen 2xl {
//   padding-left: calc((100% - theme('screens.2xl')) / 2 + theme('container.padding.2xl'));
// }

// @screen 3xl {
//   padding-left: calc((100% - theme('screens.3xl')) / 2 + theme('container.padding.3xl'));
// }
}

.swiper .swiper-pagination.pb-swiper-pagination-center-mobile{
  text-align: center;
  padding-left: theme('container.padding.DEFAULT');
  padding-right: 0px;

  @screen sm {
    text-align: left;
    padding-left: calc((100% - theme('screens.sm')) / 2 + theme('container.padding.sm'));
  }

  @screen md {
    padding-left: calc((100% - theme('screens.md')) / 2 + theme('container.padding.md'));
  }

  @screen lg {
    bottom: 10%;
    padding-left: calc((100% - theme('screens.lg')) / 2 + theme('container.padding.lg'));
  }

  @screen xl {
    padding-left: calc((100% - theme('screens.xl')) / 2 + theme('container.padding.xl'));
  }

  @screen 2xl {
    padding-left: calc((100% - theme('screens.2xl')) / 2 + theme('container.padding.2xl'));
  }

  @screen 3xl {
    padding-left: calc((100% - theme('screens.3xl')) / 2 + theme('container.padding.3xl'));
  }
}


.swiper .swiper-pagination-bullet{
width: 18px;
height: 18px;
z-index: 10;
border : 1px solid;
border-color: rgba(var(--pb-swiper-pagination-bullet-border),1);
background-color: transparent;
opacity: 1;
margin-left: 0px !important;
margin-right: 1rem !important;
}


.swiper .swiper-pagination-bullet-active {
background-color: rgba(var(--pb-swiper-pagination-bullet),1);
border-color: rgba(var(--pb-swiper-pagination-bullet),1);

}

.swiper:has(.swiper-slide.swiper-slide-active.pb-pagination-inverse-color){

.swiper-pagination-bullet{
  border-color: rgba(var(--pb-swiper-pagination-bullet-border-inverse),1);
}

.swiper-pagination-bullet-active{
  background-color: rgba(var(--pb-swiper-pagination-bullet-inverse),1);
  border-color: rgba(var(--pb-swiper-pagination-bullet-inverse),1);

}
}


.pb-form, .pb-calculator{
  .swiper-slide:not(.swiper-slide-active) form,
  .swiper-slide:not(.swiper-slide-active) a,
  .swiper-slide:not(.swiper-slide-active) button{
    @apply pb-invisible;
  }
}