.pb-tag {
    @apply pb-p-1.5 pb-text-sm-m pb-text-center pb-border pb-rounded-md md:pb-text-sm;
    background-color: rgba(var(--pb-c-tag-background-default), 0.70);
    border-color: rgba(var(--pb-c-tag-border-default), 0.70);
    color: rgba(var(--pb-c-tag-default), 1);

    &:hover {
        background-color: rgba(var(--pb-c-tag-background-default), 0.80);
        border-color: rgba(var(--pb-c-tag-border-default), 0.80);
    }
}

html[data-energy-save="true"] {
    .pb-tag {
        background-color: rgba(var(--pb-c-tag-background-default), 0.20);
        border-color: rgba(var(--pb-c-tag-border-default), 0.20);
        color: rgba(var(--pb-c-tag-default), 0.80);

        &:hover {
            background-color: rgba(var(--pb-c-tag-background-default), 0.30);
            border-color: rgba(var(--pb-c-tag-border-default), 0.30);
        } 
    }
}