@font-face {
  font-family: 'pb-icons';
  src: 
    url('../../fonts/pb-icons/pb-icons.ttf?lcjciv') format('truetype'),
    url('../../fonts/pb-icons/pb-icons.woff?lcjciv') format('woff'),
    url('../../fonts/pb-icons/pb-icons.svg?lcjciv#pb-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="pb-icon-"]:before, [class*=" pb-icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pb-icons' !important;
  font-style: normal;
  line-height: 100%;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pb-icon-yellow-fill:before {
  content: "\e940";
}
.pb-icon-arrow-up:before {
  content: "\e944";
}
.pb-icon-arrow-down:before {
  content: "\e945";
}
.pb-icon-arrow-left:before {
  content: "\e946";
}
.pb-icon-arrow-right:before {
  content: "\e947";
}
.pb-icon-chevron-up:before {
  content: "\e948";
}
.pb-icon-chevron-down:before {
  content: "\e949";
}
.pb-icon-chevron-left:before {
  content: "\e94a";
}
.pb-icon-chevron-right:before {
  content: "\e94b";
}
.pb-icon-add:before {
  content: "\e94c";
}
.pb-icon-minus:before {
  content: "\e94d";
}
.pb-icon-refresh:before {
  content: "\e94e";
}
.pb-icon-protection_and_blocking:before {
  content: "\e94f";
}
.pb-icon-support:before {
  content: "\e950";
}
.pb-icon-sort_ascending:before {
  content: "\ea07";
}
.pb-icon-sort_descending:before {
  content: "\ea06";
}
.pb-icon-drag_and_drop:before {
  content: "\ea05";
}
.pb-icon-search:before {
  content: "\ea04";
}
.pb-icon-phone:before {
  content: "\ea03";
}
.pb-icon-point_map:before {
  content: "\ea02";
}
.pb-icon-calendar:before {
  content: "\ea01";
}
.pb-icon-email:before {
  content: "\ea00";
}
.pb-icon-link-old:before {
  content: "\e9ff";
}
.pb-icon-to_share:before {
  content: "\e951";
}
.pb-icon-rss:before {
  content: "\e952";
}
.pb-icon-download-old:before {
  content: "\e953";
}
.pb-icon-upload-old:before {
  content: "\e954";
}
.pb-icon-instagram:before {
  content: "\e955";
}
.pb-icon-linkedin:before {
  content: "\e956";
}
.pb-icon-twitter:before {
  content: "\e957";
}
.pb-icon-youtube:before {
  content: "\e958";
}
.pb-icon-facebook:before {
  content: "\e959";
}
.pb-icon-check:before {
  content: "\e95a";
}
.pb-icon-euro:before {
  content: "\e95b";
}
.pb-icon-close:before {
  content: "\e95c";
}
.pb-icon-office-supplies:before {
  content: "\e95d";
}
.pb-icon-trashbin:before {
  content: "\e95e";
}
.pb-icon-operation-ok:before {
  content: "\e9fe";
}
.pb-icon-pen-edit:before {
  content: "\e9fd";
  color: #7e7e7e;
}
.pb-icon-loading:before {
  content: "\e9fc";
}
.pb-icon-loading-circle:before {
  content: "\e9fb";
}
.pb-icon-money-box:before {
  content: "\e9fa";
}
.pb-icon-mobile:before {
  content: "\e9f9";
}
.pb-icon-analytics:before {
  content: "\e9f8";
}
.pb-icon-alert-triangle:before {
  content: "\e9f7";
}
.pb-icon-account-add:before {
  content: "\e9f6";
}
.pb-icon-account-delete:before {
  content: "\e95f";
}
.pb-icon-account-deposit:before {
  content: "\e960";
}
.pb-icon-account-withdraw:before {
  content: "\e961";
}
.pb-icon-alert-round:before {
  content: "\e962";
}
.pb-icon-alert-round-fill:before {
  content: "\e963";
}
.pb-icon-alert-triangle1:before {
  content: "\e964";
}
.pb-icon-archive:before {
  content: "\e965";
}
.pb-icon-arrow-cycle:before {
  content: "\e966";
}
.pb-icon-arrow-d-dash:before {
  content: "\e967";
}
.pb-icon-arrow-dl:before {
  content: "\e968";
}
.pb-icon-arrow-dl-dash:before {
  content: "\e969";
}
.pb-icon-arrow-dr:before {
  content: "\e96a";
}
.pb-icon-arrow-dr-dash:before {
  content: "\e96b";
}
.pb-icon-arrow-enter:before {
  content: "\e96c";
}
.pb-icon-arrow-expand:before {
  content: "\e9f5";
}
.pb-icon-arrow-l-dash:before {
  content: "\e9f4";
}
.pb-icon-arrow-l-r-dash:before {
  content: "\e9f3";
}
.pb-icon-arrow-r-dash:before {
  content: "\e9f2";
}
.pb-icon-arrow-reload:before {
  content: "\e9f1";
}
.pb-icon-arrow-u-dash:before {
  content: "\e9f0";
}
.pb-icon-arrow-ul:before {
  content: "\e9ef";
}
.pb-icon-arrow-ul-dash:before {
  content: "\e9ee";
}
.pb-icon-arrow-up-down-dash:before {
  content: "\e9ed";
}
.pb-icon-arrow-ur:before {
  content: "\e96d";
}
.pb-icon-arrow-ur-dash:before {
  content: "\e96e";
}
.pb-icon-atm:before {
  content: "\e96f";
}
.pb-icon-book-open:before {
  content: "\e970";
}
.pb-icon-briefcase:before {
  content: "\e971";
}
.pb-icon-burger:before {
  content: "\e972";
}
.pb-icon-business-factory:before {
  content: "\e973";
}
.pb-icon-business-large:before {
  content: "\e974";
}
.pb-icon-business-new:before {
  content: "\e975";
}
.pb-icon-calculator:before {
  content: "\e976";
}
.pb-icon-calculator-solar:before {
  content: "\e977";
}
.pb-icon-calendar-add:before {
  content: "\e978";
}
.pb-icon-calendar-check:before {
  content: "\e979";
}
.pb-icon-calendar-x:before {
  content: "\e97a";
}
.pb-icon-card-hand:before {
  content: "\e9ec";
}
.pb-icon-card-recharge:before {
  content: "\e9eb";
}
.pb-icon-card-strip:before {
  content: "\e9ea";
}
.pb-icon-check-x:before {
  content: "\e9e9";
}
.pb-icon-check-x-dash:before {
  content: "\e9e8";
}
.pb-icon-check-yes:before {
  content: "\e9e7";
}
.pb-icon-check-yes-dash:before {
  content: "\e9e6";
}
.pb-icon-chevron-down-dash:before {
  content: "\e9e5";
}
.pb-icon-chevron-left-dash:before {
  content: "\e9e4";
}
.pb-icon-chevron-right-dash:before {
  content: "\e97b";
}
.pb-icon-chevron-up-dash:before {
  content: "\e97c";
}
.pb-icon-circles:before {
  content: "\e97d";
}
.pb-icon-cloverleaf:before {
  content: "\e97e";
}
.pb-icon-coin-euro:before {
  content: "\e97f";
}
.pb-icon-coin-percent:before {
  content: "\e980";
}
.pb-icon-coin-plus:before {
  content: "\e981";
}
.pb-icon-coin-stack:before {
  content: "\e982";
}
.pb-icon-coin-stack2:before {
  content: "\e983";
}
.pb-icon-contactless:before {
  content: "\e984";
}
.pb-icon-control-play:before {
  content: "\e985";
}
.pb-icon-control-play-circle:before {
  content: "\e986";
}
.pb-icon-control-play-rect:before {
  content: "\e987";
}
.pb-icon-control-play-square:before {
  content: "\e988";
}
.pb-icon-control-videocamera:before {
  content: "\e9e3";
}
.pb-icon-culture:before {
  content: "\e9e2";
}
.pb-icon-digital-issue:before {
  content: "\e9e1";
}
.pb-icon-digital-transactions:before {
  content: "\e9e0";
}
.pb-icon-document-dupli:before {
  content: "\e9df";
}
.pb-icon-document-plain:before {
  content: "\e9de";
}
.pb-icon-document-service-list:before {
  content: "\e9dd";
}
.pb-icon-document-signed:before {
  content: "\e9dc";
}
.pb-icon-document-warning:before {
  content: "\e9db";
}
.pb-icon-envelope:before {
  content: "\e989";
}
.pb-icon-exchange-card:before {
  content: "\e98a";
}
.pb-icon-exchange-currency:before {
  content: "\e98b";
}
.pb-icon-farmers1:before {
  content: "\e98c";
}
.pb-icon-farmers2:before {
  content: "\e98d";
}
.pb-icon-first-aid:before {
  content: "\e98e";
}
.pb-icon-flag:before {
  content: "\e98f";
}
.pb-icon-flexibility:before {
  content: "\e990";
}
.pb-icon-funds-receive:before {
  content: "\e991";
}
.pb-icon-funnel:before {
  content: "\e992";
}
.pb-icon-funnel-x:before {
  content: "\e993";
}
.pb-icon-gift:before {
  content: "\e994";
}
.pb-icon-globe:before {
  content: "\e995";
}
.pb-icon-goldbars:before {
  content: "\e996";
}
.pb-icon-graph-decrease:before {
  content: "\e9da";
}
.pb-icon-graph-increase:before {
  content: "\e9d9";
}
.pb-icon-graph-line:before {
  content: "\e9d8";
}
.pb-icon-graph-line-limit:before {
  content: "\e9d7";
}
.pb-icon-graph-line-up:before {
  content: "\e9d6";
}
.pb-icon-graph-pie:before {
  content: "\e9d5";
}
.pb-icon-green-leaf:before {
  content: "\e9d4";
}
.pb-icon-green-leaf-circle:before {
  content: "\e9d3";
}
.pb-icon-headset:before {
  content: "\e9d2";
}
.pb-icon-heart-fill:before {
  content: "\e997";
}
.pb-icon-heart-line:before {
  content: "\e998";
}
.pb-icon-hexagons:before {
  content: "\e999";
}
.pb-icon-history:before {
  content: "\e99a";
}
.pb-icon-history-add:before {
  content: "\e99b";
}
.pb-icon-history-check:before {
  content: "\e99c";
}
.pb-icon-home:before {
  content: "\e99d";
}
.pb-icon-home-aid:before {
  content: "\e99e";
}
.pb-icon-home-enter:before {
  content: "\e99f";
}
.pb-icon-home-lightning:before {
  content: "\e9a0";
}
.pb-icon-home-person:before {
  content: "\e9a1";
}
.pb-icon-home-water:before {
  content: "\e9a2";
}
.pb-icon-horn:before {
  content: "\e9a3";
}
.pb-icon-hourglass:before {
  content: "\e9a4";
}
.pb-icon-installments:before {
  content: "\e9d1";
}
.pb-icon-institution:before {
  content: "\e9d0";
}
.pb-icon-institution-arrow:before {
  content: "\e9cf";
}
.pb-icon-key:before {
  content: "\e9ce";
}
.pb-icon-laptop:before {
  content: "\e9cd";
}
.pb-icon-laptop-control:before {
  content: "\e9cc";
}
.pb-icon-lightning:before {
  content: "\e9cb";
}
.pb-icon-magnifyinglens:before {
  content: "\e9ca";
}
.pb-icon-map-chart:before {
  content: "\e9c9";
}
.pb-icon-map-goto:before {
  content: "\e9a5";
}
.pb-icon-map-location:before {
  content: "\e9a6";
}
.pb-icon-map-place:before {
  content: "\e9a7";
}
.pb-icon-map-pointer:before {
  content: "\e9a8";
}
.pb-icon-minus-dash:before {
  content: "\e9a9";
}
.pb-icon-mobile1:before {
  content: "\e9aa";
}
.pb-icon-nfc:before {
  content: "\e9ab";
}
.pb-icon-percentage:before {
  content: "\e9ac";
}
.pb-icon-percentage-down:before {
  content: "\e9ad";
}
.pb-icon-percentage-up:before {
  content: "\e9ae";
}
.pb-icon-piggybank:before {
  content: "\e9af";
}
.pb-icon-plus:before {
  content: "\e9b0";
}
.pb-icon-plus-dash:before {
  content: "\e9b1";
}
.pb-icon-professional-services:before {
  content: "\e9b2";
}
.pb-icon-professional-solutions:before {
  content: "\e9b3";
}
.pb-icon-receipt:before {
  content: "\e9b4";
}
.pb-icon-receive-key:before {
  content: "\e9b5";
  color: #1e1e1e;
}
.pb-icon-safe:before {
  content: "\e9b6";
}
.pb-icon-shield-check:before {
  content: "\e9b7";
}
.pb-icon-shield-coin:before {
  content: "\e9be";
}
.pb-icon-shield-enter:before {
  content: "\e9c6";
}
.pb-icon-shield-health:before {
  content: "\e9c7";
}
.pb-icon-sort-down:before {
  content: "\e9c8";
}
.pb-icon-sort-up:before {
  content: "\e9b8";
}
.pb-icon-three-blocks:before {
  content: "\e9b9";
}
.pb-icon-tickets:before {
  content: "\e9ba";
}
.pb-icon-tourism:before {
  content: "\e9bb";
}
.pb-icon-pb-icon-umbrella:before {
  content: "\e9bc";
}
.pb-icon-updates:before {
  content: "\e9bd";
}
.pb-icon-upgrade:before {
  content: "\ea09";
}
.pb-icon-vehicle-car:before {
  content: "\e9bf";
}
.pb-icon-vehicle-car-front:before {
  content: "\e9c0";
}
.pb-icon-vehicle-plane:before {
  content: "\e9c1";
}
.pb-icon-vehicle-train:before {
  content: "\e9c2";
}
.pb-icon-vehicle-van:before {
  content: "\e9c3";
}
.pb-icon-vehicle-van-fast:before {
  content: "\e9c4";
}
.pb-icon-wallet:before {
  content: "\e9c5";
}
.pb-icon-access_cash:before {
  content: "\e900";
}
.pb-icon-account:before {
  content: "\e901";
}
.pb-icon-account_add:before {
  content: "\e902";
}
.pb-icon-account_delete:before {
  content: "\e903";
}
.pb-icon-account_in:before {
  content: "\e904";
}
.pb-icon-account_out:before {
  content: "\e905";
}
.pb-icon-ai:before {
  content: "\e906";
}
.pb-icon-arrow-d:before {
  content: "\e907";
}
.pb-icon-arrow-l:before {
  content: "\e908";
}
.pb-icon-arrow-r:before {
  content: "\e909";
}
.pb-icon-arrows_centre:before {
  content: "\e90a";
}
.pb-icon-arrow-u:before {
  content: "\e90b";
}
.pb-icon-shield-plain:before {
  content: "\e941";
}
.pb-icon-autonomy:before {
  content: "\e90c";
}
.pb-icon-zero:before {
  content: "\e942";
}
.pb-icon-bonus:before {
  content: "\e90d";
}
.pb-icon-user_single:before {
  content: "\e93f";
}
.pb-icon-users_two:before {
  content: "\e943";
}
.pb-icon-users:before {
  content: "\ea08";
}
.pb-icon-user_sad:before {
  content: "\e93e";
}
.pb-icon-user_happy:before {
  content: "\e93d";
}
.pb-icon-user_desk:before {
  content: "\e93c";
}
.pb-icon-user_center2:before {
  content: "\e93b";
}
.pb-icon-user_center1:before {
  content: "\e93a";
}
.pb-icon-upgrade_1:before {
  content: "\e939";
}
.pb-icon-umbrella:before {
  content: "\e938";
}
.pb-icon-supermarket:before {
  content: "\e937";
}
.pb-icon-student:before {
  content: "\e936";
}
.pb-icon-speed2:before {
  content: "\e935";
}
.pb-icon-speed1:before {
  content: "\e934";
}
.pb-icon-shield-forces:before {
  content: "\e933";
}
.pb-icon-shield-coins:before {
  content: "\e932";
}
.pb-icon-Safe:before {
  content: "\e931";
}
.pb-icon-recycling:before {
  content: "\e930";
}
.pb-icon-protection:before {
  content: "\e92f";
}
.pb-icon-pouch_euro:before {
  content: "\e92e";
}
.pb-icon-pension:before {
  content: "\e92d";
}
.pb-icon-oil_euro:before {
  content: "\e92c";
}
.pb-icon-map_pointer:before {
  content: "\e92b";
}
.pb-icon-map_my_location:before {
  content: "\e92a";
}
.pb-icon-magnifyinglens-plus:before {
  content: "\e929";
}
.pb-icon-magnifyinglens-minus:before {
  content: "\e928";
}
.pb-icon-lotus_flower:before {
  content: "\e927";
}
.pb-icon-loop_out:before {
  content: "\e926";
}
.pb-icon-loop:before {
  content: "\e925";
}
.pb-icon-lightning_1:before {
  content: "\e924";
}
.pb-icon-lightbulb:before {
  content: "\e923";
}
.pb-icon-green_leaves2:before {
  content: "\e922";
}
.pb-icon-green_leaf:before {
  content: "\e91f";
}
.pb-icon-green_leaf_value:before {
  content: "\e920";
}
.pb-icon-green_leaves1:before {
  content: "\e921";
}
.pb-icon-graph_stable:before {
  content: "\e91e";
}
.pb-icon-duration_variable:before {
  content: "\e91d";
}
.pb-icon-digital-issue_plus:before {
  content: "\e91c";
}
.pb-icon-constant_variable:before {
  content: "\e91b";
}
.pb-icon-coins_currency:before {
  content: "\e91a";
}
.pb-icon-coin_yen:before {
  content: "\e919";
}
.pb-icon-coin_low:before {
  content: "\e918";
}
.pb-icon-coin_euro:before {
  content: "\e917";
}
.pb-icon-coin_dollar:before {
  content: "\e916";
}
.pb-icon-clock:before {
  content: "\e914";
}
.pb-icon-clock_quick:before {
  content: "\e915";
}
.pb-icon-circles_target:before {
  content: "\e913";
}
.pb-icon-circles_sm-med-lrg:before {
  content: "\e912";
}
.pb-icon-card_subscription_offer:before {
  content: "\e911";
}
.pb-icon-card_issue:before {
  content: "\e910";
}
.pb-icon-card_hand:before {
  content: "\e90f";
}
.pb-icon-business_investment:before {
  content: "\e90e";
}
.pb-icon-smartwatch:before {
  content: "\ea0b";
}
.pb-icon-receive-loan:before {
  content: "\ea0c";
}
.pb-icon-receive-home:before {
  content: "\ea0d";
}
.pb-icon-receive-funds:before {
  content: "\ea0e";
}
.pb-icon-pouch_points:before {
  content: "\ea0f";
}
.pb-icon-pos:before {
  content: "\ea10";
}
.pb-icon-home_family:before {
  content: "\ea11";
}
.pb-icon-green_needs:before {
  content: "\ea12";
}
.pb-icon-graph_half:before {
  content: "\ea13";
}
.pb-icon-graph_full_up:before {
  content: "\ea14";
}
.pb-icon-farmer_card:before {
  content: "\ea15";
}
.pb-icon-eye:before {
  content: "\ea16";
}
.pb-icon-engineer_tools:before {
  content: "\ea17";
}
.pb-icon-coins_currency_card:before {
  content: "\ea18";
}
.pb-icon-cogs:before {
  content: "\ea19";
}
.pb-icon-cog:before {
  content: "\ea1a";
}
.pb-icon-circles_two:before {
  content: "\ea1b";
}
.pb-icon-circle_euro:before {
  content: "\ea1c";
}
.pb-icon-babyface:before {
  content: "\ea1d";
}
.pb-icon-access_home:before {
  content: "\ea1e";
}
.pb-icon-yellow:before {
  content: "\ea0a";
}
.pb-icon-Folder_add:before {
  content: "\ea1f";
}
.pb-icon-Folder:before {
  content: "\ea20";
}
.pb-icon-covid:before {
  content: "\ea21";
}
.pb-icon-wheelchair:before {
  content: "\ea22";
}
.pb-icon-graph-pie-many:before {
  content: "\ea23";
}
.pb-icon-three-blocks2:before {
  content: "\ea24";
}
.pb-icon-graph-chart-2:before {
  content: "\ea25";
}
.pb-icon-graph-chart:before {
  content: "\ea26";
}
.pb-icon-graph-bars:before {
  content: "\ea27";
}
.pb-icon-graph-bars2:before {
  content: "\ea28";
}
.pb-icon-attach-clippy:before {
  content: "\ea29";
}
.pb-icon-link:before {
  content: "\ea2a";
}
.pb-icon-attach:before {
  content: "\ea2b";
}
.pb-icon-download:before {
  content: "\ea2c";
}
.pb-icon-share:before {
  content: "\ea2d";
}
.pb-icon-yellow-fill-new:before {
  content: "\ea2e";
}
.pb-icon-trashcan:before {
  content: "\ea2f";
}
.pb-icon-upload:before {
  content: "\ea30";
}
.pb-icon-phone-land:before {
  content: "\ea31";
}