.pb-progress-bar {
    .pb-progress-bar-item {
        @apply pb-h-2.5 pb-w-full pb-max-w-12;
        background-color: rgba(var(--pb-progress-bar-bg), 1);

        &.active {
            background-color: rgba(var(--pb-progress-bar-active), 1);
        }
    }
    #pb-progress-bar-item-first {
        @apply pb-rounded-l-3xl;
    }

    #pb-progress-bar-item-last {
        @apply pb-rounded-r-3xl;
    }
}