.pb-form {
    background-color: rgba(var(--pb-form-bg), 1);

    .pb-form-bubble {
        background-color: rgba(var(--pb-form-bubble-bg), 1);
    }

    .pb-form-bubble:before {
        @apply pb-border-b-[24px] pb-border-l-[14px] lg:pb-border-b-[40px] lg:pb-border-l-[22px];
        content: "";
        border-bottom-color: rgba(var(--pb-form-bubble-border), 1) !important;
        border-right-width: 0px;
        border-color: transparent;
        height: 0;
        left: 5px;
        bottom: -1px;
        right: -6px;
        position: absolute;
        overflow: hidden;
    }
}

html[data-sector="private"][data-energy-save="false"] {
    .pb-form {
        .pb-form-bubble .pb-text-color-brand, .pb-form-bubble {
            @apply pb-text-grey-110;
        }
        .pb-form-illustration {
            display: none;
        }

        .pb-form-bubble{
            @apply pb-pb-5;
            &:before{
                content:none;
            }
        }
    }
}

html[data-sector="personal"],
html[data-energy-save="true"] {
    .pb-form {
        .pb-form-illustration {
            display: none;
        }
    }
}
