.pb-text-color-brand {
    color: rgba(var(--pb-text-brand),1);
}

.\!pb-text-color-brand{
    color: rgba(var(--pb-text-brand),1) !important;
}

.pb-text-color-link {
    color: rgba(var(--pb-text-link),1);
}

.pb-text-color-subtle {
    color: rgba(var(--pb-text-subtle),1);
}

.pb-icon-color-default {
    color: rgba(var(--pb-icon-default),1);
}

.pb-icon-color-brand {
    color: rgba(var(--pb-icon-brand),1);
}

.pb-svg-color-brand {
    fill: rgba(var(--pb-icon-brand),1);
}

.pb-yellow-logo svg path{
    fill: rgba(var(--pb-logo-yellow),1);
}