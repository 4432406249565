@font-face {
  font-family: "Piraeus Open Sans";
  src: url('assets/fonts/pb-open-sans/woff2/PiraeusOpenSans-Regular.woff2') format('woff2'),
    url('assets/fonts/pb-open-sans/woff/PiraeusOpenSans-Regular.woff') format('woff'),
    url('assets/fonts/pb-open-sans/ttf/PiraeusOpenSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Piraeus Open Sans";
  src: url('assets/fonts/pb-open-sans/woff2/PiraeusOpenSans-Italic.woff2') format('woff2'),
    url('assets/fonts/pb-open-sans/woff/PiraeusOpenSans-Italic.woff') format('woff'),
    url('assets/fonts/pb-open-sans/ttf/PiraeusOpenSans-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Piraeus Open Sans";
  src: url('assets/fonts/pb-open-sans/woff2/PiraeusOpenSans-Bold.woff2') format('woff2'),
    url('assets/fonts/pb-open-sans/woff/PiraeusOpenSans-Bold.woff') format('woff'),
    url('assets/fonts/pb-open-sans/ttf/PiraeusOpenSans-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Piraeus Open Sans";
  src: url('assets/fonts/pb-open-sans/woff2/PiraeusOpenSans-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/pb-open-sans/woff/PiraeusOpenSans-BoldItalic.woff') format('woff'),
    url('assets/fonts/pb-open-sans/ttf/PiraeusOpenSans-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Piraeus Open Serif";
  src: url('assets/fonts/pb-open-serif/woff2/PiraeusOpenSerif-Regular.woff2') format('woff2'),
    url('assets/fonts/pb-open-serif/woff/PiraeusOpenSerif-Regular.woff') format('woff'),
    url('assets/fonts/pb-open-serif/ttf/PiraeusOpenSerif-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Piraeus Open Serif";
  src: url('assets/fonts/pb-open-serif/woff2/PiraeusOpenSerif-Italic.woff2') format('woff2'),
    url('assets/fonts/pb-open-serif/woff/PiraeusOpenSerif-Italic.woff') format('woff'),
    url('assets/fonts/pb-open-serif/ttf/PiraeusOpenSerif-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
}