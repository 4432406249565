.pb-icon-list{

    ul li{
        @apply pb-flex pb-items-center pb-gap-4 pb-text-lg-m lg:pb-text-lg pb-mb-5 last:pb-mb-0;

        &:before{
            // chevron-right
            @apply pb-icon-color-default;
            content: "\e94b";
            font-family: 'pb-icons';
            display: inline-block;
            font-size: 32px;
            line-height: 100%;
        }
    } 
}

.pb-icon-list-diy-calculator{
    ul:not(:first-child){
        @apply pb-mt-2;
    }
    ul li{
        @apply pb-flex pb-items-start pb-gap-1 pb-text-sm-m lg:pb-text-sm pb-mb-1 last:pb-mb-0;

        &:before{
            // pb-icon-operation-ok
            @apply pb-icon-color-default;
            content: "\e9fe";
            font-family: 'pb-icons';
            display: inline-block;
            font-size: 16px;
            line-height: 140%;
        }
    } 
}

.pb-step-list-icon {

    ul li{
      @apply pb-flex pb-items-center pb-gap-4 pb-text-md-m lg:pb-text-md pb-mb-4 last:pb-mb-0;
      
      &:before{
        @apply pb-icon-color-default;
        // arrow-right;
        content:"\e909";
        font-family: 'pb-icons';
        display: inline-block;
        font-size: 32px;
        line-height: 100%;
      }
    }   
  }