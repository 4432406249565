.pb-link{
    @apply pb-text-link-md-m lg:pb-text-link-md pb-underline pb-underline-offset-[3px];
    color: rgba(var(--pb-text-link),1);
    // outline: 2px solid rgba(var(--pb-text-link), 0);
    // outline-offset: 1px;
    i{
        @apply pb-text-i-24 pb-icon-color-default;
        color: rgba(var(--pb-icon-default),1);
        text-decoration: none !important;

        &::before{
            display: inline-block;;
        }
    }
}

.pb-link:hover{
    color: rgba(var(--pb-text-link-hover),1);
    transform: scale(1.01);    
}

.pb-link:focus-visible{
    color: rgba(var(--pb-text-link-hover),1);
    outline: 2px solid #286ED5;
    outline-offset: -1px;
}

.pb-link.disabled{
    cursor: default;
    pointer-events: none;
    color: rgba(var(--pb-text-link-disabled),1);
    i{
        color: rgba(var(--pb-text-link-disabled),1);
    }
}
