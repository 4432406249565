body {
    background-color: rgba(var(--pb-background-default), 1);
    color: rgba(var(--pb-text-default), 1);
    @apply pb-text-md-m lg:pb-text-md pb-font-sans pb-mx-auto;
    max-width: 2560px;
}

.grecaptcha-badge{
    bottom: 70px !important;
}

.page-wrapper, 
#pb-search-modal, 
#pb-knowledge-hub-search-modal,
#energySaveModal,
.pb-chatty .pb-chatty--open
.pb-contactFormModal{
    @apply pb-mx-auto;
    max-width: 2560px;
} 