.pb-c-hero-article-text {
    /* TODO What color will we put here and is it gonna change with the other  sectors??? */
    @apply pb-min-h-[297px] lg:pb-min-h-[35.25rem];
    background-color: rgba(var(--pb-c-hero-article-text), 1);
}

.pb-c-hero {
    border-color: rgba(var(--pb-c-hero-slide-border), 1);

    &.pb-hero-theme--default {
        background-color: rgba(var(--pb-c-hero-internal-white-background), 1);
    }
    &.pb-hero-theme--brand {
        background-color: rgba(var(--pb-c-hero-internal-yellow-background), 1)
    }
    &.pb-hero-theme--green {
        background-color: rgba(var(--pb-c-hero-internal-green-background), 1)
    }
    
    &.pb-c-hero-landing-illustration{
        background-color: rgba(var(--pb-c-hero-landing-illustration-background), 1)
    }


    .pb-container-left,
    .pb-container-right {
        // margin: auto;
        @apply pb-px-0 pb-max-w-full;
        /* @screen xs {
            padding-left: theme('container.padding.xs');
            padding-right: theme('container.padding.xs');
            max-width: theme('screens.xs');
        } */

        @screen sm {
            @apply pb-px-0 pb-max-w-full;
        }

        @screen md {
            @apply pb-px-0 pb-max-w-full;
        }
    }
    .pb-container-left {
        margin-right:auto;
        @screen lg {
            padding-left: theme("container.padding.lg");
            padding-right: 0rem;
        }
    
        @screen xl {
            padding-left: theme("container.padding.xl");
            padding-right: 0rem;
            @media (min-width: 1445px){
                padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.xl"));
            }
        }
    
        // @screen 2xl {
        //     @media (min-width: 1731px){
        //         padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.2xl"));
        //     }
        //     padding-left: theme("container.padding.2xl");
        //     padding-right: 0rem;
        // }
    
        // @screen 3xl {
        //     padding-left: calc((100% - 1444px) / 2);
        //     padding-right: 0rem;
        // }
    }
    
    .pb-container-right {
        margin-left:auto;
        @screen lg {
            padding-right: theme("container.padding.lg");
            padding-left: 0rem;
        }
    
        @screen xl {
            padding-right: theme("container.padding.xl");
            padding-left: 0rem;
            @media (min-width: 1445px){
                padding-right: calc(calc((100% - 1444px) / 2) + theme("container.padding.xl"));
            }
        }
    
        // @screen 2xl {
        //     @media (min-width: 1731px){
        //         padding-right: calc(calc((100% - 1444px) / 2) + theme("container.padding.2xl"));
        //     }
        //     padding-right: theme("container.padding.2xl");
        //     padding-left: 0rem;
        // }
    
        // @screen 3xl {
        //     padding-right: calc((100% - 1444px) / 2 );
        //     padding-left: 0rem;
        // }
    }

    .pb-card-hero{
        background-color: rgba(var(--pb-c-hero-slide-bubble), 1) !important;
    }
    /* button {

} */
    .pb-card-hero-mask,
    .pb-card-hero-mask:after {
        [data-sector="default"] {
            @screen lg {
                background-color: transparent !important;
                border-bottom-color: transparent !important;
            }
        }
    }
    .pb-card-hero-mask:after{
        content: "";
        position: absolute;
        width: 25px;
        height: 0;
        bottom: -25px;
        left: 0px;
        z-index: 2;
        border-bottom-width: 26px;
        border-left-width: 20px;
        border-right-width: 0px;
        border-color: transparent;
        border-bottom-color: rgba(var(--pb-c-hero-slide-bubble), 1);
        transform: rotate(180deg);
    }
    .pb-hero-mask-theme--default.pb-card-hero-mask:after {
        border-bottom-color: rgba(var(--pb-c-hero-internal-white-background), 1)!important;
    }
    .pb-hero-mask-theme--brand.pb-card-hero-mask:after {
        border-bottom-color: rgba(var(--pb-c-hero-internal-yellow-background), 1)!important;
    }
    .pb-hero-mask-theme--green.pb-card-hero-mask:after {
        border-bottom-color: rgba(var(--pb-c-hero-internal-green-background), 1)!important;
    }
    .pb-card-hero:after,
    .pb-card-hero-campaign:after {
        content: "";
        position: absolute;
        width: 25px;
        height: 0;
        bottom: -25px;
        left: 0px;
        z-index: 2;
        border-bottom-width: 26px;
        border-left-width: 20px;
        border-right-width: 0px;
        border-color: transparent;
        border-bottom-color: rgba(var(--pb-c-hero-slide-bubble), 1);
        transform: rotate(180deg);
    }

    &.pb-c-hero-article-image {
        border: none;
    }

    &.pb-c-hero-article-image:before, &.pb-c-hero-article-text:before {
        content: "";
        @apply pb-border-b-[25px] pb-border-l-[14px] lg:pb-border-b-[30px] lg:pb-border-l-[18px];
        // border-left-width: 6px;
        border-right-width: 0px;
        // border-bottom-width: 15px;
        border-color: transparent;
        border-bottom-color: rgba(var(--pb-background-default), 1);
        height: 0;
        left: 5px;
        bottom: -1px;
        right: -6px;
        position: absolute;
        overflow: hidden;
    }

    .pb-card-hero.pb-theme--brand:after,
    .pb-card-hero-article.pb-theme--brand:after {
        border-bottom-color: rgba(var(--pb-c-hero-slide-bubble), 1);
    }


    //Mask on lg
    @media (screen(lg)) {
        .pb-hero-mask-right-wrapper {
            aspect-ratio: 228 / 150;
        }
        .pb-hero-mask-left-wrapper {
            aspect-ratio: 228 / 150;
        }

        .pb-hero-mask-right {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNTIwIDEwMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNLTAuMDAwNjEwMzUyIDg5NS42MDNMMzkyLjE2OSAwLjAwMzU0MDA0TDE1MjAgMFYxMDAwTDE3MC4wMDcgMTAwMFY5ODQuOTAxTC0wLjAwMDYxMDM1MiA4OTUuNjAzWiIgZmlsbD0iI0ZGRDkwMCIvPgo8L3N2Zz4=");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNTIwIDEwMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNLTAuMDAwNjEwMzUyIDg5NS42MDNMMzkyLjE2OSAwLjAwMzU0MDA0TDE1MjAgMFYxMDAwTDE3MC4wMDcgMTAwMFY5ODQuOTAxTC0wLjAwMDYxMDM1MiA4OTUuNjAzWiIgZmlsbD0iI0ZGRDkwMCIvPgo8L3N2Zz4=");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
        }

        .pb-hero-mask-left {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNTIwIDEwMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNMTEyNy45NyA4OTUuNkwxNTIwIC0wLjAwMDQ4ODI4MUwwIDBWMTAwMEwxMjk3LjkyIDEwMDBWOTg0Ljg5OEwxMTI3Ljk3IDg5NS42WiIgZmlsbD0iI0ZGRDkwMCIvPgo8L3N2Zz4=");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNTIwIDEwMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNMTEyNy45NyA4OTUuNkwxNTIwIC0wLjAwMDQ4ODI4MUwwIDBWMTAwMEwxMjk3LjkyIDEwMDBWOTg0Ljg5OEwxMTI3Ljk3IDg5NS42WiIgZmlsbD0iI0ZGRDkwMCIvPgo8L3N2Zz4=");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
        }
    }
    //Mask on 3xl 
    @media (screen(3xl)) {
        .pb-hero-mask-right-wrapper {
            aspect-ratio: 38 / 25;
        }
        .pb-hero-mask-left-wrapper {
            aspect-ratio: 38 / 25;
        }

        .pb-hero-mask-right {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTUyMCAxMDAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTS0wLjAwMDYxMDM1MiA4OTUuNjAzTDM5Mi4xNjkgMC4wMDM1NDAwNEwxNTIwIDBWMTAwMEwxNzAuMDA3IDEwMDBWOTg0LjkwMUwtMC4wMDA2MTAzNTIgODk1LjYwM1oiIGZpbGw9IiNGRkQ5MDAiLz4KPC9zdmc+");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTUyMCAxMDAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTS0wLjAwMDYxMDM1MiA4OTUuNjAzTDM5Mi4xNjkgMC4wMDM1NDAwNEwxNTIwIDBWMTAwMEwxNzAuMDA3IDEwMDBWOTg0LjkwMUwtMC4wMDA2MTAzNTIgODk1LjYwM1oiIGZpbGw9IiNGRkQ5MDAiLz4KPC9zdmc+");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
        }

        .pb-hero-mask-left {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTUyMCAxMDAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTTExMjcuOTcgODk1LjZMMTUyMCAtMC4wMDA0ODgyODFMMCAwVjEwMDBMMTI5Ny45MiAxMDAwVjk4NC44OThMMTEyNy45NyA4OTUuNloiIGZpbGw9IiNGRkQ5MDAiLz4KPC9zdmc+");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTUyMCAxMDAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTTExMjcuOTcgODk1LjZMMTUyMCAtMC4wMDA0ODgyODFMMCAwVjEwMDBMMTI5Ny45MiAxMDAwVjk4NC44OThMMTEyNy45NyA4OTUuNloiIGZpbGw9IiNGRkQ5MDAiLz4KPC9zdmc+");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
        }
    }
    //Mask on 3xl 
    @media (screen(4xl)) {
        .pb-hero-mask-right-wrapper {
            aspect-ratio: 163 / 150;
            width:1300px;
            height: 1200px;
        }
        .pb-hero-mask-left-wrapper {
            aspect-ratio: 163 / 150;
            width:1300px;
            height: 1200px;
        }

        .pb-hero-mask-right {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzAwIDEyMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNLTAuMDAwNzMyNDIyIDEwNzQuNzJMNDcwLjg2MSAwLjAwMzkwNjI1TDEzMDAgMC4wMDQxMjU5N1YxMjAwTDIwNC4xMjEgMTIwMFYxMTgxLjg4TC0wLjAwMDczMjQyMiAxMDc0LjcyWiIgZmlsbD0iI0ZGRDkwMCIvPgo8L3N2Zz4=");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzAwIDEyMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNLTAuMDAwNzMyNDIyIDEwNzQuNzJMNDcwLjg2MSAwLjAwMzkwNjI1TDEzMDAgMC4wMDQxMjU5N1YxMjAwTDIwNC4xMjEgMTIwMFYxMTgxLjg4TC0wLjAwMDczMjQyMiAxMDc0LjcyWiIgZmlsbD0iI0ZGRDkwMCIvPgo8L3N2Zz4=");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
        }

        .pb-hero-mask-left {
            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzAwIDEyMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNODI5LjU2NCAxMDc0LjcyTDEzMDAgMEwwIDAuMDAwNTg1OTM3VjEyMDBMMTAzMy41IDEyMDBWMTE4MS44OEw4MjkuNTY0IDEwNzQuNzJaIiBmaWxsPSIjRkZEOTAwIi8+Cjwvc3ZnPg==");
            mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzAwIDEyMDAiIGZpbGw9Im5vbmUiPgo8cGF0aCBkPSJNODI5LjU2NCAxMDc0LjcyTDEzMDAgMEwwIDAuMDAwNTg1OTM3VjEyMDBMMTAzMy41IDEyMDBWMTE4MS44OEw4MjkuNTY0IDEwNzQuNzJaIiBmaWxsPSIjRkZEOTAwIi8+Cjwvc3ZnPg==");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
        }
    }
}

html[data-sector="default"], html[data-sector="business"]{
    .pb-c-hero {
        .pb-card-hero-mask,
        .pb-card-hero-mask:after {
            @screen lg {
                background-color: transparent !important;
                border-bottom-color: transparent !important;
            }
        }
    }
}

html[data-sector="personal"][data-energy-save="false"] {
    .pb-c-hero .pb-c-hero-article-text,
    .pb-c-hero .pb-card-hero,
    .pb-c-hero .pb-card-hero-mask {
        // background-color: transparent !important;
        background-color: rgba(255, 255, 255, 0.60) !important;
    }
    /* .pb-card-hero {
    border: 2px solid rgba(var(--pb-c-hero-slide-border), 1);
    &.pb-card-hero-campaign {
        @screen sm {
            border-color: rgba(var(--pb-c-hero-slide-bubble), 1);
        }
    }
} */
    .pb-card-hero-article,
    .pb-card-hero-campaign {
        background-color: transparent;
        border-top: 2px solid rgba(var(--pb-c-hero-slide-border), 1);
        border-right: 2px solid rgba(var(--pb-c-hero-slide-border), 1);
        border-bottom: 0px;
        border-left: 0px;
    }

    .pb-card-hero-article{
        @apply lg:pb-border-none pb-mb-auto lg:pb-mb-0;
    }

    .pb-c-hero.pb-c-hero-article-text:before{
        @apply lg:pb-content-none;
        border-bottom-width: 35px;
    }

    .pb-card-hero:after,
    .pb-card-hero.pb-card-hero-article:after,
    .pb-card-hero.pb-card-hero-campaign:after,
    .pb-card-hero.pb-card-hero-article:before {
        content: none;
    }
    .pb-card-hero-article:before {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYwMCIgaGVpZ2h0PSIyNjAwIiB2aWV3Qm94PSIwIDAgMjYwMCAyNjAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDI1OTguMTZWMjU3MUgxOS4wNDAyTDQuNTU3NjIgMjU5OC4xNkgxWiIgZmlsbD0ibm9uZSIvPgo8cGF0aCBkPSJNMjYwMCAyNTcxLjVIMTkuMDQwMkw0LjU1NzYyIDI1OTguNjZIMVYyNTcxLjVWMCIgc3Ryb2tlPSIjRkZEOTAwIi8+Cjwvc3ZnPgo=");
        position: absolute;
        bottom: 0px;
        left: 0px;

        @screen lg{
            content: none !important;
        }
    }
    .pb-c-hero-campaign {
        .pb-hero-overflow-personal {
            overflow: clip;
            @apply pb-pb-6 lg:pb-pb-13;
        }

        img {
            @apply -pb-mt-6 lg:pb-mt-0;
        }
    }
    .pb-card-hero-campaign:before {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYwMCIgaGVpZ2h0PSIyNjAwIiB2aWV3Qm94PSIwIDAgMjYwMCAyNjAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDI1OTguMTZWMjU3MUgxOS4wNDAyTDQuNTU3NjIgMjU5OC4xNkgxWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTI2MDAgMjU3MS41SDE5LjA0MDJMNC41NTc2MiAyNTk4LjY2SDFWMjU3MS41VjAiIHN0cm9rZT0iI0ZGRDkwMCIvPgo8L3N2Zz4K");
        position: absolute;
        bottom: -34px;
        left: 0px;

        &.pb-card-big-image{
            bottom: -35px!important;
        }

        @screen sm {
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNjAwIiBoZWlnaHQ9IjI2MDAiIHZpZXdCb3g9IjAgMCAyNjAwIDI2MDAiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSI+CjxwYXRoIGQ9Ik0xIDI1OTguMTZWMjU3MUgxOS4wNDAyTDQuNTU3NjIgMjU5OC4xNkgxWiIgZmlsbD0id2hpdGUiIG9wYWNpdHk9Ii42Ii8+CjxwYXRoIGQ9Ik0yNjAwIDI1NzEuNUgxOS4wNDAyTDQuNTU3NjIgMjU5OC42NkgxVjI1NzEuNVYwIiBzdHJva2U9IiNGRkQ5MDAiLz4KPC9zdmc+");
        }
    }
    .pb-card-hero.pb-card-hero-article {
        border: none;
    }
    // black and white filter
    .pb-c-hero {
        
        img {
            @apply pb-grayscale;
        }
        
    }
}

html[data-sector="private"][data-energy-save="false"] {
    .pb-card-hero.pb-card-hero-campaign,
    .pb-c-hero.pb-c-hero-article-text,
    .pb-card-hero.pb-card-hero-article {
        background-color: rgba(var(--pb-palette-black), 1) !important;

        &:before,
        &:after {
            border-bottom-color: rgba(
                var(--pb-palette-black),
                1
            ) !important;
        }
    }

    .pb-c-hero .pb-card-hero-mask:after{
        content:none;
    }
}

html[data-energy-save="true"] {
    // .pb-c-hero-mask {


        // background-color: rgba(var(--pb-palette-green-70), 1);
        // @media (screen(lg)) {
        //     &.pb-hero-energy-no-illustration {
        //         height: 455px;

        //         .pb-container-right,
        //         .pb-container-left {
        //             margin: 0;

        //             .pb-card-hero-mask {
        //                 @apply pb-justify-end;
        //             }
        //         }
        //     }
        // }
    // }

    .pb-card-hero:after,
    .pb-card-hero-campaign:after {
        content: none;
    }

    // hero internal no bubble
    .pb-c-hero.pb-c-hero-mask {
      .pb-card-hero-mask:after {
        border-bottom-color: transparent!important;
      }
    }

    .pb-c-hero.pb-c-hero-article-image,
    // .pb-c-hero-internal, ** TODO
    .pb-c-hero.pb-c-hero-campaign,
    .pb-c-hero.pb-c-hero-mask{
        img {
            display: inline !important;
        }
    }

    .pb-c-hero .pb-card-hero.pb-card-hero-campaign{
        background-color: inherit!important;
    }
}

html[data-sector="holdings"] {
  .pc-c-hero.pb-c-hero-article-text:after,
  .pb-card-hero:after,
  .pb-card-hero.pb-card-hero-article:after,
  .pb-card-hero.pb-card-hero-campaign:after,
  .pb-card-hero.pb-card-hero-article:before,
  .pb-c-hero.pb-c-hero-article-image:before,
  .pb-c-hero.pb-c-hero-article-text:before,
  .pb-c-hero .pb-card-hero-mask:after {
    content: none;
  }

    .pb-c-hero-article-text.pb-c-hero-article-text-green{
        background-color: rgba(var(--pb-c-hero-article-text-green), 1) !important;
        color: rgba(var(--pb-palette-white), 1)!important;
        .pb-text-color-brand{
            color: rgba(var(--pb-palette-white), 1)!important;
        }
    }
    .pb-c-hero-article-text.pb-c-hero-article-text-warm-grey{
        background-color: rgba(var(--pb-c-hero-article-text-warm-grey), 1) !important;
    }
    .pb-c-hero-article-text.pb-c-hero-article-text-yellow{
        background-color: rgba(var(--pb-c-hero-article-text-yellow), 1) !important;
    }

}