.pb-m-filter {
    scrollbar-width: none;

    .pb-title-color {
        color: rgba(var(--pb-m-filter-title), 1);
    }

    .pb-c-filter-item {
        @apply pb-flex pb-justify-center pb-items-center pb-gap-2 pb-text-md-m md:pb-text-md pb-font-bold pb-py-4 pb-px-5 md:pb-px-6 pb-cursor-pointer pb-no-underline pb-w-fit;
        border-radius: 6.25rem;
        background-color: rgba(var(--pb-c-filter-item), 1);
        color: rgba(var(--pb-c-filter-item-text), 1);
    
        i {
            font-size: 24px;
            color: rgba(var(--pb-c-filter-item-icon), 1);
            /* To-do fill or color? */
        }
    
        &:hover {
            background-color: rgba(var(--pb-c-filter-item-hover), 1);
            color: rgba(var(--pb-c-filter-item-text-hover), 1);
    
            i {
                color: rgba(var(--pb-c-filter-item-icon-hover), 1);
            }
        }
    
        &.pb-filter--active {
            background-color: rgba(var(--pb-c-filter-item-active), 1);
            color: rgba(var(--pb-c-filter-item-text-active), 1);
    
            i {
                color: rgba(var(--pb-c-filter-item-icon-active), 1);
            }
        }
    
        &:disabled {
            cursor: default;
            background-color: rgba(var(--pb-c-filter-item-disabled), 1);
            color: rgba(var(--pb-c-filter-item-text-disabled), 1);
    
            i {
                color: rgba(var(--pb-c-filter-item-icon-disabled), 1);
            }
        }
    }

    .pb-c-filter-item.pb-filter--green {
        background-color: rgba(var(--pb-c-filter-item-green), 1);
        color: rgba(var(--pb-c-filter-item-green-text), 1);
    
        i {
            color: rgba(var(--pb-c-filter-item-green-text), 1);
        }
    
        &:hover {
            background-color: rgba(var(--pb-c-filter-item-green-hover), 1);
            color: rgba(var(--pb-c-filter-item-green-text-hover), 1);
    
            i {
                color: rgba(var(--pb-c-filter-item-green-text-hover), 1);
            }
        }
    
        &.pb-filter--active {
            background-color: rgba(var(--pb-c-filter-item-green-active), 1);
            color: rgba(var(--pb-c-filter-item-green-text-active), 1);
    
            i {
                color: rgba(var(--pb-c-filter-item-green-text-active), 1);
            }
        }
    
        &:disabled {
            background-color: rgba(var(--pb-c-filter-item-green-disabled), 1);
            color: rgba(var(--pb-c-filter-item-green-text-disabled), 1);
    
            i {
                color: rgba(var(--pb-c-filter-item-green-text-disabled), 1);
            }
        }
    }

}