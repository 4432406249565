//          These does not need "pb--animate"       \\

//          Cards Animations-Hovers
.pb-card--scaleUP {
    transition: transform 0.2s ease-in-out;
}
.pb-card--scaleUP:hover {
    @apply pb-drop-shadow-lg;
    transform: scale(1.01);
}

.pb-card--scaleUP.pb--shadow-none:hover{
    @apply pb-drop-shadow-none;
}

.pb-card--liftUP {
    transition: transform 0.2s ease;
}
.pb-card--liftUP:hover {
    @apply pb-drop-shadow-lg;
    transform: translateY(-20px) scale(1.05);
}

//ScaleUp and ScaleDown animation for Opening a Modal
.pb-scaleUp-wrapper {
    transform: translate(0%, 0%) scale(0.3);
}
.pb-scaleUp,
.pb-scaleDown {
    display: block;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.pb-scaleUp {
    -webkit-transform: translate(0%, 0%) scale(1);
    transform: translate(0%, 0%) scale(1);
}

.pb-scaleDown {
    -webkit-transform: translate(0%, 0%) scale(0.3);
    transform: translate(0%, 0%) scale(0.3);
}


//In each animation below we need to add "pb--animate" to run properly in time
//  "pb--OnFocus its the class that we add next to pb--animate to trigger the animation"
//=======================================================================================\\
@screen lg {
    .pb-animateReveal {
        opacity: 0;
        transform: translateY(40px);
        transition: all 0.18s ease-out;
        transition-property: unset !important;
    }

    .pb--animate.pb--OnFocus .pb-animateReveal {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }

    .pb--animate.pb--OnFocus .pb-fadeInRight {
        animation: PBfadeInRight 0.5s ease-in-out forwards;
    }

    .pb--animate.pb--OnFocus .pb-fadeInLeft {
        animation: PBfadeInLeft 0.5s ease-in-out forwards;
    }

    .pb-fadeInRight,
    .pb-fadeInLeft {
        opacity: 0;
    }

    .pb-FadeUp {
        opacity: 0;
    }
    .pb--animate.pb--OnFocus.pb-FadeUp {
        opacity: 1;
        animation: Fade 0.6s linear forwards;
    }
}

@for $i from 1 through 20 {
    @media (min-width: 1024px) {
        .pb-animateReveal:nth-child(#{$i}) {
            transition-delay: $i * 0.2s;
        }
    }
}

/* ---------------------------------------------------------- */

@keyframes PBfadeInRight {
    0% {
        transform: translateX(20%);
    }
    30% {
        opacity: 0.3;
    }
    60% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes PBfadeInLeft {
    0% {
        transform: translateX(-20%);
    }
    30% {
        opacity: 0.3;
    }
    60% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes Fade {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* ---------------------------------------------------------- */

.pb--animate.pb--OnFocus .pb-slideInRight {
    animation: PBslideInRight 0.5s ease-in-out forwards;
}

.pb--animate.pb--OnFocus .pb-slideInLeft {
    animation: PBslideInLeft 0.5s ease-in-out forwards;
}

@keyframes PBslideInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes PBslideInLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

/* ---------------------------------------------------------- */

.pb-moveup {
    display: block;
    animation: PBmoveUp 0.5s ease-in-out forwards;
}

.pb-movedown {
    display: block;
    animation: PBmoveDown 0.5s ease-in-out forwards;
}

@keyframes PBmoveUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-110%);
    }
}

@keyframes PBmoveDown {
    0% {
        transform: translateY(-110%);
    }
    100% {
        transform: translateY(0%);
    }
}
