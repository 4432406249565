@layer components {
    .pb-banner-image-title{
        color: rgba(var(--pb-c-input-normal), 1);
    }

  .pb-banner-marketing {
    background-color: rgba(var(--pb-m-banner-marketing-background), 1);

    img{
      height: 7.5rem;;

      @screen lg{
          height:8.75rem;
      }
    }

  }

 .pb-m-article-gallery{
  @screen lg{
    .pb-gallery--false > div:nth-child(odd){
      flex-direction: row!important;
    }
    .pb-gallery--true > div:nth-child(even){
      flex-direction: row!important;
    }
  }
 }

  .pb-banner-conversion {
    background-color: rgba(var(--pb-c-banner-conversion-background), 1);
    border-color: rgba(var(--pb-c-banner-conversion-border), 1);
  } 

  .pb-banner-btn {
    /* @apply pb-w-full; */
    width: 100% !important;
    @screen lg {
      width: fit-content !important;
    }
  }

  .pb-banner-mask-left {
    @apply pb-w-full lg:pb-w-7/12;
    -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MTggNTYwIiBmaWxsPSJub25lIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03OTEuNSAwSDBWNTYwSDYyNkw5MTcuODAzIDU3LjVMNzkxLjUgOC4zODA3NVYwWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+");
    mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MTggNTYwIiBmaWxsPSJub25lIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03OTEuNSAwSDBWNTYwSDYyNkw5MTcuODAzIDU3LjVMNzkxLjUgOC4zODA3NVYwWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    aspect-ratio: 41 / 25;
  }

  .pb-banner-mask-right {
    @apply pb-w-full lg:pb-w-7/12;
    -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MTkgNTYwIiBmaWxsPSJub25lIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05MTguNDE4IDBIMTY1LjE2OFY4LjM4MDc1TDI5MS40MTggNTcuNjAxTDAgNTYwSDkxOC40MThWMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
    mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MTkgNTYwIiBmaWxsPSJub25lIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05MTguNDE4IDBIMTY1LjE2OFY4LjM4MDc1TDI5MS40MTggNTcuNjAxTDAgNTYwSDkxOC40MThWMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    aspect-ratio: 41 / 25;
  }

  .pb-banner-image-background-yellow{
    .pb-banner-mask-left, .pb-banner-mask-right {

      @media screen and (max-width:640px){
        mask: none !important; 
      }
    }
  }

  .pb-banner-mask-lines {
    @apply pb-w-full lg:pb-w-7/12;
    -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MzcgNTYwIj4KICA8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzM4LjcsMGgtMTY1LjZ2NC41bDQ4LjgsMjYuM0wwLDU2MGgxNjUuNnYtNC41bC00OC44LTI2LjNMMzM4LjcsMFpNMzcxLjYsMHY0LjVsNDguOCwyNi4zLTIyMS45LDUyOS4yaDE2NS42di00LjVsLTQ4LjgtMjYuM0w1MzcuMSwwaC0xNjUuNlpNNTcxLjIsMHY0LjVsNDguOCwyNi4zLTIyMS45LDUyOS4yaDE2NS42di00LjVsLTQ4LjgtMjYuM0w3MzYuOCwwaC0xNjUuNloiLz4KPC9zdmc+");
    mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MzcgNTYwIj4KICA8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzM4LjcsMGgtMTY1LjZ2NC41bDQ4LjgsMjYuM0wwLDU2MGgxNjUuNnYtNC41bC00OC44LTI2LjNMMzM4LjcsMFpNMzcxLjYsMHY0LjVsNDguOCwyNi4zLTIyMS45LDUyOS4yaDE2NS42di00LjVsLTQ4LjgtMjYuM0w1MzcuMSwwaC0xNjUuNlpNNTcxLjIsMHY0LjVsNDguOCwyNi4zLTIyMS45LDUyOS4yaDE2NS42di00LjVsLTQ4LjgtMjYuM0w3MzYuOCwwaC0xNjUuNloiLz4KPC9zdmc+");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

  .pb-m-banner-4items-center, .pb-m-banner-image-list-gallery {
      background-color: rgba(var(--pb-m-banner-4items-center-background), 1);
      border: 4px solid rgba(var(--pb-m-banner-4items-border), 1);
  }
}

.pb-banner-image-background-grey{
  background-color: rgba(var(--pb-palette-grey-10), 1 );
}

.pb-banner-image-background-warm-grey{
  background-color: rgba(var(--pb-palette-warm-grey-10), 1 );
}

.pb-banner-image-background-yellow{
  background-color: rgba(var(--pb-palette-yellow-60), 1 );
}

html[data-energy-save="true"] {
  .pb-banner-image-background-grey,
  .pb-banner-image-background-warm-grey,
  .pb-banner-image-background-yellow{
      background-color: rgba(var(--pb-background-default), 1 );
  }
}


// black and white filter

html[data-sector="personal"] {
    .pb-c-banner-illustration,
    .pb-banner-withMask,
    .pb-banner-imageList,
    .pb-banner-marketing,
    .pb-m-card-featured,
    .pb-m-banner-tabs,
    .pb-c-banner-image,
    .pb-banner-image-mask,
    .pb-m-article {
        img {
            @apply pb-grayscale;
        }
    }
}

