section{
    @apply pb-mt-11 lg:pb-mt-13;
}

section .pb-c-hero, section.pb-c-hero, 
section:has(div.pb-c-hero) ,  
section.pb-header-lvl-1{
    @apply pb-mt-0
}

.pb-sp-t-none {
    @apply pb-mt-0;
}

.pb-sp-t-7 {
    @apply pb-mt-7;
}

.pb-sp-t-8 {
    @apply pb-mt-8;
}

.pb-sp-t-9 {
    @apply pb-mt-9;
}

.pb-sp-t-11 {
    @apply pb-mt-11;
}

.pb-sp-t-13 {
    @apply pb-mt-13;
}

.pb-sp-t-14 {
    @apply pb-mt-14;
}

.pb-sp-t-15 {
    @apply pb-mt-15;
}

.pb-sp-t-16 {
    @apply pb-mt-[16.5rem];
}

.pb-sp-b-none {
    @apply pb-mb-0;
}

.pb-sp-b-7 {
    @apply pb-mb-7;
}

.pb-sp-b-8 {
    @apply pb-mb-8;
}

.pb-sp-b-9 {
    @apply pb-mb-9;
}

.pb-sp-b-11 {
    @apply pb-mb-11;
}

.pb-sp-b-13 {
    @apply pb-mb-13;
}

.pb-sp-b-14 {
    @apply pb-mb-14;
}

.pb-sp-b-15 {
    @apply pb-mb-15;
}

.pb-sp-b-16 {
    @apply pb-mb-[16.5rem];
}

.-pb-sp-t-none {
    @apply pb-mt-0;
}

.-pb-sp-t-7 {
    @apply -pb-mt-7;
}

.-pb-sp-t-8 {
    @apply -pb-mt-8;
}

.-pb-sp-t-9 {
    @apply -pb-mt-9;
}

.-pb-sp-t-11 {
    @apply -pb-mt-11;
}

.-pb-sp-t-13 {
    @apply -pb-mt-13;
}

.-pb-sp-t-14 {
    @apply -pb-mt-14;
}

.-pb-sp-t-15 {
    @apply -pb-mt-15;
}

.-pb-sp-t-16 {
    @apply -pb-mt-[16.5];
}

.-pb-sp-b-none {
    @apply -pb-mb-0;
}

.-pb-sp-b-7 {
    @apply -pb-mb-7;
}

.-pb-sp-b-8 {
    @apply -pb-mb-8;
}

.-pb-sp-b-9 {
    @apply -pb-mb-9;
}

.-pb-sp-b-11 {
    @apply -pb-mb-11;
}

.-pb-sp-b-13 {
    @apply -pb-mb-13;
}

.-pb-sp-b-14 {
    @apply -pb-mb-14;
}

.-pb-sp-b-15 {
    @apply -pb-mb-15;
}

.-pb-sp-b-16 {
    @apply -pb-mb-16;
}