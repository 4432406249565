.pb-btn {
    @apply pb-flex pb-justify-center pb-items-center pb-gap-2 pb-text-md pb-font-bold pb-border pb-py-4 pb-px-5 pb-cursor-pointer pb-no-underline;

    &.pb-btn--large{
        @apply pb-text-lg pb-px-6 pb-py-[20px];

        i{
            font-size: 32px;
            font-weight: 400;
        }
    }
    &:disabled {
        cursor: default;
    }
    
    i{
        font-size: 24px;
    }

    &:focus-visible {
      outline: 2px solid #286ED5;
      outline-offset: 1px;
    }
}

.pb-btn-icon {
    @apply pb-p-3 pb-border pb-cursor-pointer pb-no-underline pb-flex pb-justify-center pb-items-center;
    
    i{
        font-size: 24px;
    }
}

.pb-btn-primary {
    background-color: rgba(var(--pb-button-primary), 1);
    color: rgba(var(--pb-button-primary-text), 1);
    border-color: rgba(var(--pb-button-primary-border), 1);

    &:hover {
        background-color: rgba(var(--pb-button-primary-hover), 1);
        color: rgba(var(--pb-button-primary-text-hover), 1);
        border-color: rgba(var(--pb-button-primary-border-hover), 1);

        i {
            color: rgba(var(--pb-button-primary-icon-hover), 1);
        }
    }

    &:active {
        background-color: rgba(var(--pb-button-primary-active), 1);
        color: rgba(var(--pb-button-primary-text-active), 1);
        border-color: rgba(var(--pb-button-primary-border-active), 1);

        i {
            color: rgba(var(--pb-button-primary-icon-active), 1);
        }
    }

    &:disabled {
        background-color: rgba(var(--pb-button-primary-disabled), 1);
        color: rgba(var(--pb-button-primary-text-disabled), 1);
        border-color: rgba(var(--pb-button-primary-border-disabled), 1);

        i {
            color: rgba(var(--pb-button-primary-icon-disabled), 1);
        }
    }

    i {
        color: rgba(var(--pb-button-primary-icon), 1);
        /* To-do fill or color? */
    }
}

.pb-btn-primary-brand {
    background-color: rgba(var(--pb-button-primary-brand), 1);
    color: rgba(var(--pb-button-primary-brand-text), 1);
    border-color: rgba(var(--pb-button-primary-brand-border), 1);

    &:hover {
        background-color: rgba(var(--pb-button-primary-brand-hover), 1);
        color: rgba(var(--pb-button-primary-brand-text-hover), 1);
        border-color: rgba(var(--pb-button-primary-brand-border-hover), 1);

        i {
            color: rgba(var(--pb-button-primary-brand-icon-hover), 1);
        }
    }

    &:active {
        background-color: rgba(var(--pb-button-primary-brand-active), 1);
        color: rgba(var(--pb-button-primary-brand-text-active), 1);
        border-color: rgba(var(--pb-button-primary-brand-border-active), 1);

        i {
            color: rgba(var(--pb-button-primary-brand-icon-active), 1);
        }
    }

    &:disabled {
        background-color: rgba(var(--pb-button-primary-brand-disabled), 1);
        color: rgba(var(--pb-button-primary-brand-text-disabled), 1);
        border-color: rgba(var(--pb-button-primary-brand-border-disabled), 1);

        i {
            color: rgba(var(--pb-button-primary-brand-icon-disabled), 1);
        }
    }

    i {
        color: rgba(var(--pb-button-primary-brand-icon), 1);
    }
}

.pb-btn-primary-esg {
    background-color: rgba(var(--pb-button-primary-esg), 1);
    color: rgba(var(--pb-button-primary-esg-text), 1);
    border-color: rgba(var(--pb-button-primary-esg-border), 1);

    &:hover {
        background-color: rgba(var(--pb-button-primary-esg-hover), 1);
        color: rgba(var(--pb-button-primary-esg-text-hover), 1);
        border-color: rgba(var(--pb-button-primary-esg-border-hover), 1);

        i {
            color: rgba(var(--pb-button-primary-esg-icon-hover), 1);
        }
    }

    &:disabled {
        background-color: rgba(var(--pb-button-primary-disabled), 1);
        color: rgba(var(--pb-button-primary-text-disabled), 1);
        border-color: rgba(var(--pb-button-primary-border-disabled), 1);

        i {
            color: rgba(var(--pb-button-primary-icon-disabled), 1);
        }
    }

    i {
        color: rgba(var(--pb-button-primary-esg-icon), 1);
    }
}

.pb-btn-secondary {
    background-color: rgba(var(--pb-button-secondary), 0);
    color: rgba(var(--pb-button-secondary-text), 1);
    border-color: rgba(var(--pb-button-secondary-border), 1);

    &:hover {
        background-color: rgba(var(--pb-button-secondary-hover), 1);
        color: rgba(var(--pb-button-secondary-text-hover), 1);
        border-color: rgba(var(--pb-button-secondary-border-hover), 1);

        i {
            color: rgba(var(--pb-button-secondary-icon-hover), 1);
        }
    }

    &:active {
        background-color: rgba(var(--pb-button-secondary-active), 1);
        color: rgba(var(--pb-button-secondary-text-active), 1);
        border-color: rgba(var(--pb-button-secondary-border-active), 1);

        i {
            color: rgba(var(--pb-button-secondary-icon-active), 1);
        }
    }

    &:disabled {
        background-color: rgba(var(--pb-button-secondary-disabled), 1);
        color: rgba(var(--pb-button-secondary-text-disabled), 1);
        border-color: rgba(var(--pb-button-secondary-border-disabled), 1);

        i {
            color: rgba(var(--pb-button-secondary-icon-disabled), 1);
        }
    }

    i {
        color: rgba(var(--pb-button-secondary-icon), 1);
    }
}

.pb-btn-transparent {
    background-color: rgba(var(--pb-button-transparent), 0);
    color: rgba(var(--pb-button-transparent-text), 1);
    border-color: rgba(var(--pb-button-transparent-border), 0);

    &:hover {
        background-color: rgba(var(--pb-button-transparent-hover), 1);
        color: rgba(var(--pb-button-transparent-text-hover), 1);
        border-color: rgba(var(--pb-button-transparent-border-hover), 1);

        i {
            color: rgba(var(--pb-button-transparent-icon-hover), 1);
        }
    }

    &:active {
        background-color: rgba(var(--pb-button-transparent-active), 1);
        color: rgba(var(--pb-button-transparent-text-active), 1);
        border-color: rgba(var(--pb-button-transparent-border-active), 1);

        i {
            color: rgba(var(--pb-button-transparent-icon-active), 1);
        }
    }

    &:disabled {
        background-color: rgba(var(--pb-button-transparent-disabled), 1);
        color: rgba(var(--pb-button-transparent-text-disabled), 1);
        border-color: rgba(var(--pb-button-transparent-border-disabled), 1);

        i {
            color: rgba(var(--pb-button-transparent-icon-disabled), 1);
        }
    }

    i {
        color: rgba(var(--pb-button-transparent-icon), 1);
    }
}
.pb-btn-blog-tag{
    @apply pb-flex pb-justify-center pb-items-center pb-border pb-rounded-3xl pb-px-4 pb-py-[6px] hover:pb-cursor-pointer active:pb-cursor-default;
    color : rgba(var(--pb-blog-tag-text--color),1);
    border-color : rgba(var(--pb-blog-tag-text--color),1);
}