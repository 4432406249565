.pb-container{
    max-width: 100% !important;

    @screen xl{
        max-width:1444px !important;
    }
}

.lg\:pb-container, .sm\:pb-container{
    @screen lg{
        max-width:1444px !important;
    }
}

.pb-container-left,
.pb-container-right {
    // margin: auto;
    padding-left: theme("container.padding.xs");
    padding-right: theme("container.padding.xs");
    max-width: 100%;
    /* @screen xs {
            padding-left: theme('container.padding.xs');
            padding-right: theme('container.padding.xs');
            max-width: theme('screens.xs');
        } */

    @screen sm {
    padding-left: theme("container.padding.sm");
    padding-right: theme("container.padding.sm");
    // max-width: theme("screens.sm");
    }

    @screen md {
    padding-left: theme("container.padding.md");
    padding-right: theme("container.padding.md");
    // max-width: theme("screens.md");
    }
}

.pb-container-left {
    @screen lg {
        padding-left: theme("container.padding.lg");
        padding-right: 0rem;
    }

    @screen xl {
        padding-left: theme("container.padding.xl");
        @media (min-width: 1445px){
            padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.xl"));
        }
        padding-right: 0rem;
    }

    // @screen 2xl {
    //     @media (min-width: 1731px){
    //         padding-left: calc(calc((100% - 1444px) / 2) + theme("container.padding.2xl"));
    //     }
    //     padding-left: theme("container.padding.2xl");
    //     padding-right: 0rem;
    // }

    // @screen 3xl {
    //     padding-left: calc((100% - 1444px) / 2);
    //     padding-right: 0rem;
    // }
}

.pb-container-right {
    @screen lg {
        padding-right: theme("container.padding.lg");
        padding-left: 0rem;
    }

    @screen xl {
        padding-right: theme("container.padding.xl");
        padding-left: 0rem;
    }

    @screen xl {
        padding-right: theme("container.padding.xl");
        @media (min-width: 1445px){
            padding-right: calc(calc((100% - 1444px) / 2) + theme("container.padding.xl"));
        }
        padding-left: 0rem;
    }

    // @screen 2xl {
    //     @media (min-width: 1731px){
    //         padding-right: calc(calc((100% - 1444px) / 2) + theme("container.padding.2xl"));
    //     }
    //     padding-right: theme("container.padding.2xl");
    //     padding-left: 0rem;
    // }

    // @screen 3xl {
    //     padding-right: calc((100% - 1444px) / 2);
    //     padding-left: 0rem;
    // }
}