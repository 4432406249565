app-accordion-item:last-of-type dl, .pb-c-menu-accordion:last-of-type{
    @apply pb-border-none;
}

app-accordion-item:not(:last-of-type) dl, .pb-c-menu-accordion:not(:last-of-type){
    @apply pb-border-b;
}


.pb-c-accordion, .pb-c-menu-accordion {

	border-color: rgba(var(--pb-c-accordion-border), 1);
	color: rgba(var(--pb-c-accordion-text), 1);

	dt {
		user-select: none;
		-webkit-user-select: none;
		transition: 0.2s;

		i.pb-icon-add {
			float: right;
			transition: 0.2s;
		}

		&.pb-c-accordion--expanded {
			i.pb-icon-add:before {
				content: "\e94d" !important;
				opacity: 1;
			}

			~dd {
				@apply pb-block;
			}
		}

		~dd {
			@apply pb-hidden pb-m-0 pb-pt-1 pb-pb-5;
		}

	}

}

@screen lg{
		
	details > summary.pb-summary-hoverable:hover{
		@apply pb-border-y;
		border-color: rgba(var(--pb-c-accordion-border--hover), 1);
		color: rgba(var(--pb-c-accordion-text--hover), 1);
	}
	
	details[open] > summary.pb-summary-hoverable:hover{
		@apply pb-border-t pb-border-b-0;
		border-color: rgba(var(--pb-c-accordion-border--hover), 1);
		color: rgba(var(--pb-c-accordion-text--hover), 1);
	}
}

@media screen and (max-width:1023px) {
	
	details > summary.pb-summary-hoverable:active{
		@apply pb-border-y;
		border-color: rgba(var(--pb-c-accordion-border--hover), 1);
		color: rgba(var(--pb-c-accordion-text--hover), 1);
	}
	
	details[open] > summary.pb-summary-hoverable:active{
		@apply pb-border-t pb-border-b-0;
		border-color: rgba(var(--pb-c-accordion-border--hover), 1);
		color: rgba(var(--pb-c-accordion-text--hover), 1);
	}
}



details[open] > summary i.pb-icon-add:before {
	content: "\e94d" !important;
	opacity: 1;
}

details > summary {
    list-style: none;
}
details > summary::-webkit-details-marker {
	display: none;
}