@tailwind base;
@tailwind components;
@tailwind utilities;

// theme coloring
@import "assets/scss/themes/default.scss";
@import "assets/scss/themes/holdings.scss";
@import "assets/scss/themes/personal.scss";
@import "assets/scss/themes/private.scss";
@import "assets/scss/themes/business.scss";
@import "assets/scss/themes/energy.scss";

// base 
@import "assets/scss/base/color-palette.scss";
@import "assets/scss/base/pb-fonts.css";
@import "assets/scss/base/pb-icons.scss";
@import "assets/scss/base/text-color.scss";
@import "assets/scss/base/typography.scss";
@import "assets/scss/base/icon-list.scss";
@import "assets/scss/base/container.scss";
@import "assets/scss/base/spacing.scss";
@import "assets/scss/base/animation.scss";

// components
@import "assets/scss/components/body.scss";
@import "assets/scss/components/cards.scss";
@import "assets/scss/components/swiper.scss";
@import "assets/scss/components/buttons.scss";
@import "assets/scss/components/banner.scss";
@import "assets/scss/components/filter.scss";
@import "assets/scss/components/form-elements.scss";
@import "assets/scss/components/video.scss";
@import "assets/scss/components/tabs.scss";
@import "assets/scss/components/hero.scss";
@import "assets/scss/components/tag.scss";
@import "assets/scss/components/calculator.scss";
@import "assets/scss/components/accordion.scss";
@import "assets/scss/components/tooltip.scss";
@import "assets/scss/components/progress-bar.scss";
@import "assets/scss/components/forms.scss";
@import "assets/scss/components/link.scss";

@import '~animate.css/animate.min.css';

// swiper modules
@import "../node_modules/swiper/swiper.scss";
@import "../node_modules/swiper/modules/pagination.scss";
@import "../node_modules/swiper/modules/navigation.scss";
@import "../node_modules/swiper/modules/thumbs.scss";
@import "../node_modules/swiper/modules/effect-fade.scss";
@import "../node_modules/plyr/dist/plyr.css";
@import "../node_modules/choices.js/public/assets/styles/choices.min.css";
@import "../node_modules/air-datepicker/air-datepicker.css";


// a[target='_blank']:after {
//   content: '\1F5D7'
// }

// .logo {
//   background-image: url('assets/images/sc_logo.svg');
//   display: block;
//   height: 48px;
//   width: 221px;
// }

// /*
//   Hides Sitecore Experience Editor markup,
//   if you run the app in connected mode while the Sitecore cookies
//   are set to edit mode.
// */
// .scChromeData, .scpm { display: none !important; }

// /* 
//   Style for default content block
// */
// .contentTitle {
//   font-size: 3.5rem;
//   font-weight: 300;
//   line-height: 1.2;
// }

// a {
//   text-decoration: none;
// }

// a:hover {
//   text-decoration: underline;
// }
