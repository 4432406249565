.pb-article {
    @apply pb-max-w-[52.5rem];

    /* Typography, Font-size , font-family etc... */
    h3 {
        @apply pb-font-sans pb-text-h-sm;
    }

    /* Spacing */
    // &>*:not(:first-child, p) {
    //     @apply pb-mt-9;
    // }

    & h3+p {
        @apply pb-mt-4;
    }

    li {
        @apply pb-my-0;
    }

    // Maybe we need it maybe not :)
    // img {
    //     background: lightgray 50% / cover no-repeat;
    // }

    &.pb--noBackground{
        div , span , p , h1 , h2 ,h3 ,h4 ,h5{
            background-color: inherit!important;
        }
    }

    a{
        @apply pb-underline pb-underline-offset-[3px];
        color: rgba(var(--pb-text-link),1);
        font-size: inherit !important;
        
        &:hover{
            color: rgba(var(--pb-text-link-hover),1);
            transform: scale(1.01);    
        }
        
        &:focus-visible{
            color: rgba(var(--pb-text-link-hover),1);
            outline: 2px solid #286ED5;
            outline-offset: -1px;
        }
        
        
    }

}

.pb-article-piraeus {
    --tw-prose-body: rgba(var(--pb-text-default), 1);
    --tw-prose-headings: rgba(var(--pb-text-default), 1);
    --tw-prose-lead: rgba(var(--pb-text-default), 1);
    --tw-prose-links: rgba(var(--pb-text-default), 1);
    --tw-prose-bold: rgba(var(--pb-text-default), 1);
    --tw-prose-counters: rgba(var(--pb-text-default), 1);
    --tw-prose-bullets: rgba(var(--pb-text-default), 1);
    --tw-prose-hr: rgba(var(--pb-text-default), 1);
    --tw-prose-quotes: rgba(var(--pb-text-default), 1);
    --tw-prose-quote-borders: rgba(var(--pb-text-default), 1);
    --tw-prose-captions: rgba(var(--pb-text-default), 1);
    --tw-prose-kbd: rgba(var(--pb-text-default), 1);
    --tw-prose-kbd-shadows: 15 23 42;
    --tw-prose-code: rgba(var(--pb-text-default), 1);
    --tw-prose-pre-code: rgba(var(--pb-text-default), 1);
    --tw-prose-pre-bg: rgba(var(--pb-text-default), 1);
    --tw-prose-th-borders: rgba(var(--pb-text-default), 1);
    --tw-prose-td-borders: rgba(var(--pb-text-default), 1);

    & > table {
        @apply pb-block pb-overflow-x-auto pb-whitespace-nowrap;
    }

    table {
        width: 100% !important;
        margin: 0px;
        /* border: 1px solid #CAC3AF; */
        // min-width: 800px;

        thead{
            background-color: #F7F6F3!important;

            th{
                border-bottom: 1px solid #EAE7DF;
                align-content: center;
                padding: 16px;
            }

            th:not(:last-child){
                border-right: 2px solid rgba(var(--pb-background-default), 1);
            }
        }

        tbody{

            th,td{
                padding: 16px;
            }
            tr:not(:last-of-type){
                border-bottom: 1px solid #EAE7DF;
            }

            // th:not(:last-child), td:not(:last-child){
            //     border-right: 2px solid rgba(var(--pb-background-default), 1);
            // }   
        }
    }
}

.pb-article-content-holdings{
    h2, h3, h4{
        @apply pb-text-h-sm-m lg:pb-text-h-sm pb-font-bold pb-my-6;
    }

    table{

        tbody{

            tr{
                @apply pb-border-b pb-border-grey-20;

                &:first-child{
                    @apply pb-border-t pb-border-grey-20;
                }
            }

            th:first-child, td:first-child{
                @apply pb-font-bold pb-text-h-xs-m lg:pb-text-h-xs
            }

            th,td{
                padding: 28px 0px;
            }
        }
    }

    ul{
        @apply pb-list-disc pb-space-y-2 pb-pl-5;
    }
}

html[data-energy-save="true"] {
    table thead{
        background-color: rgba(var(--pb-c-hero-slide-bubble), 1) !important;
    }
}