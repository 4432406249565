@layer components {
    .pb-card {
        @apply pb-flex pb-flex-col pb-justify-between;
        background-color: rgba(var(--pb-featured-card-default), 1);

        img {
            @apply pb-w-full pb-object-cover pb-brightness-90 pb-flex-none;
        }

        * {
            border-color: rgba(var(--pb-featured-card-default-border), 1);
        }

        &.pb-card-featured {
            background-color: rgba(var(--pb-featured-card-default), 1);

            * {
                border-color: rgba(var(--pb-featured-card-default-border), 1);
            }

            &.pb-theme--brand {
                background-color: rgba(var(--pb-featured-card-brand), 1);

            * {
                border-color: rgba(var(--pb-featured-card-brand-border), 1);
            }
            }
        }
        &.pb-card-featured .pb-card-top:after,
        &.pb-card-product .pb-card-top:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 0;
            bottom: -18px;
            left: 0px;
            z-index: 2;
            border-bottom-width: 20px;
            border-left-width: 14px;
            border-right-width: 0px;
            border-color: transparent;
            transform: rotate(180deg);
        }

        &.pb-card-product .pb-card-top,
        &.pb-card-featured.pb-theme--brand .pb-card-top{
            background-color: rgba(var(--pb-product-card-default), 1);
        }
        
        &.pb-card-featured.pb-theme--default .pb-card-top:after {
            border-bottom-color: rgba(var(--pb-featured-card-default-border), 1);
        }
        &.pb-card-featured.pb-theme--brand .pb-card-top:after {
            border-bottom-color: rgba(var(--pb-featured-card-brand), 1);
        }

        &.pb-card-product .pb-card-top:after {
            border-bottom-color: rgba(var(--pb-product-card-default), 1);
            left: 0px;
        }
        &.pb-card-product {
            // background-color: rgba(var(--pb-product-card-default), 1);
            &.pb-theme--default {
                background-color: rgba(
                    var(--pb-product-card-default-product),
                    1
                );

                * {
                    border-color: rgba(
                        var(--pb-product-card-default-product-border),
                        1
                    );
                }
            }

            &.pb-theme--green {
                background-color: rgba(var(--pb-product-card-green), 1);

                * {
                    border-color: rgba(var(--pb-product-card-green-border), 1);
                }
            }

            // .pb-card-top {
            //     color: rgba(var(--pb-palette-black), 1);
            // }

            .pb-card-bottom {
                background-color: rgba(var(--pb-product-card-bottom), 1);
                border-color: rgba(var(--pb-product-card-bottom-border), 1);
            }

            .pb-card-product-img-placeholder {
                background-color: rgba(var(--pb-product-card-bottom), 1);
            }

            hr {
                border-color: rgba(
                    var(--pb-product-card-bottom-divider),
                    1
                ) !important;
            }

            &.pb-card-product-with-img {
                .pb-card-product-img-placeholder {
                    @apply pb-flex;
                    max-height: 295px;

                    img,
                    svg {
                        @apply pb-w-full pb-h-full pb-m-auto pb-brightness-100;
                        // box-shadow: 0px 8px 10px -6px rgba(40, 39, 35, 0.12),
                        //     0px 20px 25px -5px rgba(40, 39, 35, 0.12);
                    }
                }
            }
        }

        // &.pb-card-product,
        // &.pb-card-featured {
        //     max-width: 407px;

        //     .pb-card-product-img-placeholder {
        //         height: 204px; 
        //         clip-path: polygon(15px 0, 101% 0, 101% 101%, -100% 101%, 0 10%, 5px 10%);
        //     }
        // }

        &.pb-c-card-document,
        &.pb-c-card-document-horizontal {
            @apply pb-border pb-w-full;
            background-color: rgba(
                var(--pb-card-document-background-default),
                1
            );
            border-color: rgba(var(--pb-card-document-border-default), 1);

            div:first-child > div:first-child {
                color: rgba(var(--pb-card-document-label-default), 1);
            }
        }
        &.pb-c-card-document {
            max-width: 297px;
            img {
                @apply pb-mt-1;
                clip-path: polygon(
                    15px 0,
                    101% 0,
                    101% 101%,
                    0 101%,
                    0 10%,
                    5px 10%
                );
            }

             // TODO: hover states on other sectors
            &:hover {
              svg path {
                fill: rgba(var(--pb-card-document-svg-desktop--hover), 1);
                transition: fill 0.2s ease-in-out;
              }
            }

            svg {
              @apply pb-ml-auto;
              max-width: 248px;
              height: 100%;

              path {
                fill: rgba(var(--pb-card-document-svg-desktop), 1);
              }
             
            }
        }
        &.pb-c-card-document-horizontal {
            @apply pb-flex-col lg:pb-flex-row-reverse pb-gap-x-4 pb-max-w-none;
            > div:first-child {
                @apply lg:pb-w-3/4 pb-p-4 lg:pb-p-0 lg:pb-pr-5 lg:pb-py-4;
            }
            // > div:nth-child(2) {
            //     @apply lg:pb-w-1/4;
            // }
            img,
            svg {
              @apply pb-ml-auto lg:pb-ml-0;
              max-width: 248px;
              height: 100%;
            }
             // TODO: hover states on other sectors
             &:hover {
              svg path {
                fill: rgba(var(--pb-card-document-svg-desktop--hover), 1);
                transition: fill 0.2s ease-in-out;
              }
            }
            svg {
              path {
                fill: rgba(var(--pb-card-document-svg-desktop), 1);
              }
            }
        }

        &.pb-card-promo {
            max-width: 297px;

            img {
                margin-bottom: -1rem;
                height: 198px;
            }
            :last-child {
                @apply pb-border-0;
                // border-left: 0px;
                // border-top: 0px;
                // border-color: rgba(var(--pb-featured-card-default), 1);
            }

            :last-child:after {
                content: "";
                border-left-width: 6px;
                border-right-width: 0px;
                border-bottom-width: 16px;
                border-color: transparent;
                border-bottom-color: rgba(var(--pb-featured-card-default), 1);
                height: 0;
                left: 5px;
                top: 1px;
                right: 0px;
                position: absolute;
                overflow: hidden;
            }

            &.pb-theme--brand :last-child:after {
                border-bottom-color: rgba(var(--pb-featured-card-brand), 1);
            }

            &.pb-theme--green :last-child:after {
                border-bottom-color: rgba(var(--pb-product-card-green), 1);
            }
        }

        &.pb-theme--brand {
            background-color: rgba(var(--pb-featured-card-brand), 1);

            * {
                border-color: rgba(var(--pb-featured-card-brand-border), 1);
            }
        }
        &.pb-theme--green {
            background-color: rgba(var(--pb-featured-card-green), 1);

            * {
                border-color: rgba(var(--pb-featured-card-green-border), 1);
            }
        }
    }

    .pb-m-document-cards {
        @apply pb-py-5;

        &.pb--warm-grey{
            background-color: rgba(var(--pb-m-document-cards-background), 1);
            .pb-c-filter-item{
                background-color: rgba(var(--pb-c-filter-item-documents), 1);
            }
        }
        

        @screen lg {
          @apply pb-py-7;
        }

        &.pb-m-document--small {
            .pb-c-card-document-horizontal {
                > div:first-child {
                    @apply lg:pb-w-4/5 pb-p-4 lg:pb-p-0 lg:pb-pr-5 lg:pb-py-4;
                }

                > div:nth-child(2) {
                    @apply lg:pb-w-1/5;
                }
            }
            & .pb--small{
                @screen lg{
                    max-width: 846px !important;
                }
                .pb-c-card-document-horizontal{
                    > div:first-child {
                        @apply lg:pb-w-full;
                    }
    
                    > div:nth-child(2) {
                        @apply lg:pb-w-auto;
                    }
                }
            }
        }

        .pb-c-card-document a.pb-tag {
            @apply pb-hidden;
        }

        .pb-document--grid-mode {
            @apply pb-gap-y-4 pb-gap-x-6 md:pb-grid-cols-2 lg:pb-gap-y-5 lg:pb-grid-cols-3 xl:pb-grid-cols-4;
        }

        .pb-document--row-mode {
            @apply pb-gap-y-4 lg:pb-gap-y-6;
        }

        &.pb-theme--brand {
            background-color: rgba(var(--pb-featured-card-brand), 1);

            * {
                border-color: rgba(var(--pb-featured-card-brand-border), 1);
            }
        }

        &.pb-theme--green {
            background-color: rgba(var(--pb-featured-card-green), 1);

            * {
                border-color: rgba(var(--pb-featured-card-green-border), 1);
            }
        }
    }

    .pb-m-product-comparison {
        .pb-card-comparison {
            background-color: rgba(var(--pb-featured-card-default), 1);

            .pb-card-comparison-img-placeholder img{
                @apply pb-object-cover pb-h-[115px] md:pb-h-[167px];
                display: block!important;
                
            }

            div.pb-divide-y,
            div.pb-divide-y p{
                border-color: rgba(var(--pb-border-disabled), 1);
            }
        }
    }

    html[data-energy-save="true"] {
        .pb-card-product-img-placeholder {
            @apply pb-hidden;
        }

        .pb-card.pb-card-product {
            .pb-card-top {
                border-bottom: none;
                color: rgba(var(--pb-text-white), 1);
            }

            .pb-card-bottom {
                border:1px solid rgba(255, 255, 255, 0.20);
                border-top: none;
            }
        }
        .pb-card-bottom {
            border-top: none;
        }

        .pb-card {
            &.pb-card-featured .pb-card-top:after {
                content: none;
            }

            &.pb-card-promo {

                :last-child.pb-card-promo-border {
                    @apply pb-border pb-bg-green-60;
                    
                }

                :last-child:after{
                    display: none;
                }
            }


            &.pb-c-card-document {
                a {
                    display: none;
                }
            }

            &.pb-c-card-document-horizontal {
                flex-direction: row;

                a {
                    display: none;
                }

                > div:first-child {
                    @apply pb-pl-5;
                }

                svg, .pb-tag{
                    display: none;
                }
            }
        }
    }

    // black and white filter
    html[data-sector="personal"] {
        .pb-card {
            // &.pb-card-promo,
            // &.pb-card-featured
            // &.pb-c-card-document,
            // &.pb-c-card-document-horizontal {
                
            img {
                @apply pb-grayscale;
            }
        }
    }

    // remove chat bubble
    html[data-sector="private"], html[data-sector="holdings"] {
        .pb-card {
            &.pb-card-featured .pb-card-top:after,
            &.pb-card-product .pb-card-top:after{
                content: none;
            }

            &.pb-card-promo {

                img{
                    @apply pb-mb-0;
                }

                :last-child:after{
                    display: none;
                }
            }
        }
    }

    .pb-card-promo-horizontal {
        background-color: rgba(var(--pb-featured-card-default), 1);
        border-color: rgba(var(--pb-featured-card-default-border), 1);
    }
    .pb-theme--brand {
        background-color: rgba(var(--pb-featured-card-brand), 1);

        * {
            border-color: rgba(var(--pb-featured-card-brand-border), 1);
        }
    }
    .pb-theme--green {
        background-color: rgba(var(--pb-featured-card-green), 1);

        * {
            border-color: rgba(var(--pb-featured-card-green-border), 1);
        }
    }
}
