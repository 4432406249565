@layer components {
    form {
        label:first-child {
            @apply pb-font-semibold pb-text-sm pb-block pb-mb-2;
            flex-grow: 1;
            flex-shrink: 0;
        }

        label~div {
            @apply pb-flex pb-items-center pb-gap-x-3 pb-flex-wrap;
        }

        label>span {
            color: rgba(var(--pb-c-input-normal), 1);
            @apply pb-font-semibold pb-text-sm;
        }

        input~label {
            @apply pb-text-md pb-mb-0;
        }

        p {
            @apply pb-mt-2 pb-text-sm;
            /* flex-grow: 1;
            flex-shrink: 0; */
        }

        .pb-form-textarea-info {
            @apply pb-flex pb-items-center pb-justify-between pb-text-sm pb-mt-2;

            p {
                @apply pb-mt-0;
            }

            p~div {
                @apply pb-flex;
            }
        }

        /* Labels and Text on Disabled input */
        input:disabled~label,
        input:disabled~p,
        input:disabled~div>*,
        select:disabled~p,
        textarea:disabled~.pb-form-textarea-info>*,
        .pb-form-label-top--disabled,
        .pb-form-label-top--disabled>span {
            color: rgba(var(--pb-c-input-disabled), 1);
        }

        /* Labels and Text on Invalid/Error input */
        input:invalid~p,
        textarea:invalid~.pb-form-textarea-info>p {
            color: rgba(var(--pb-text-error), 1);
        }

        /* input group */
        .pb-input-group {
            @apply pb-relative pb-flex pb-items-center pb-flex-wrap;

            a>i {
                @apply pb-text-i-24 pb-absolute pb-right-4 pb-top-3;
                // color: rgba(var(--pb-c-input-normal), 1);
            }

            span {
                @apply pb-text-i-24 pb-absolute pb-top-3 pb-left-4;
                color: rgba(var(--pb-c-input-normal), 1);
            }

            p {
                @apply pb-mt-2 pb-text-sm;
            }

            input[type=search] {
                @apply pb-pr-8;
                /* -webkit-text-fill-color:rgba(var(--pb-c-input-search-x-icon), 1); */
            }

            /* TODO : change x icon */
            input[type=search]::-webkit-search-cancel-button {
                -webkit-appearance: none;
                appearance: none;
                content: "\e9e9" !important;
                font-family: 'pb-icons' !important;
                transform: scale(1) !important;
            }

            // input[type=text].pb-form-input {
            //     @apply pb-pl-8;
            // }
        }

        .pb-input-group:has(span) input[type=text].pb-form-input {
            // @apply pb-pl-8; //only if input has icon apply pl-8
            padding-left: 56px !important;
        }

        .pb-error-showcase {
            border-color: rgba(var(--pb-c-input-border-error), 1) !important;
            border-width: 2px !important;
            --tw-ring-color: transparent;
        }

        /* Form elements States */
        .pb-form-input,
        .pb-form-textarea,
        .pb-form-range {
            @apply pb-w-full;
        }

        .pb-form-input,
        .pb-form-textarea,
        input:-internal-autofill-selected {

            border-color: rgba(var(--pb-c-input-border-normal), 1);
            color: rgba(var(--pb-c-input-normal), 1);
            background-color: rgba(var(--pb-c-input-background-normal), 1);
            @apply pb-text-md pb-border-[1px] pb-px-5 pb-py-2.5 focus:pb-ring-1 focus:pb-ring-offset-1 focus:pb-shadow-none;

            /* Hover state */
            &:hover {
                border-color: rgba(var(--pb-c-input-border-hover), 1);
            }

            /* Active state */
            &:active {
                /* color: rgba(var(--pb-c-input-active), 1); */
                border-color: rgba(var(--pb-c-input-border-active), 1);
                --tw-ring-color: transparent;
            }

            /* Focused state */
            &:focus {
                --tw-ring-color: rgba(var(--pb-c-input-border-focus), 1);
                margin: 0px 2px;
                /* option:hover{
                    background-color: rgba(var(--pb-c-input-option-hover), 1);
                } */
            }

            /* Disabled state */
            &:disabled {
                color: rgba(var(--pb-c-input-disabled), 1);
                border-color: rgba(var(--pb-c-input-border-disabled), 1);
                --tw-ring-color: transparent;
            }

            /* Invalid state */
            &:invalid {
                border-color: rgba(var(--pb-c-input-border-error), 1) !important;
                border-width: 2px !important;
                --tw-ring-color: transparent;
            }
        }

        /* TODO: Changes pending from designers */
        /* Range input */

        .pb-calculator-mini,
        .pb-calculator-midi, 
        .pb-calculator-maxi {
            > label {
                @apply pb-text-md-m lg:pb-text-md;
            }
            input[type=text]{
                background-color: rgba(var(--pb-c-input-background-normal), 1);
            }
        }

        .pb-calculator-midi, 
        .pb-calculator-maxi {
            >label {
                @apply pb-font-normal;
            }
            >p, input:disabled~p {
                color: rgba(var(--pb-text-subtle), 1);
            }
        }
        .pb-calculator-mini {
            >p, input:disabled~p {
                color: rgba(var(--pb-text-subtle), 1);
            }
        }
        
        .pb-form-range-yellow, 
        .pb-form-range-brand, 
        .pb-form-range-esg{
            height: 12px;
            width: 100%;
            outline: none;
            transition: background 450ms ease-in;
            -webkit-appearance: none;

            &::-webkit-slider-thumb{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                -webkit-appearance: none;
                cursor: pointer;
            }

            &:disabled::-webkit-slider-thumb{
                display: none;
            }

        }

        .pb-form-range-yellow{
            background: linear-gradient(to right, #FFD900 0%, #FFD900 40%, #fff 40%, #fff 100%);

            &::-webkit-slider-thumb{
                background: #FFD900;
            }
        }
        
        .pb-form-range-brand{
            background: linear-gradient(to right, rgba(var(--pb-c-input-btn-background-normal), 1) 0%, rgba(var(--pb-c-input-btn-background-normal), 1) 40%, #fff 40%, #fff 100%);

            &::-webkit-slider-thumb{
                background: rgba(var(--pb-c-input-btn-background-normal), 1);
            }
        }

        .pb-form-range-esg{
            background: linear-gradient(to right, #DDD94B 0%, #DDD94B 40%, #fff 40%, #fff 100%);

            &::-webkit-slider-thumb{
                background: #DDD94B;
            }
        }

        .choices__list .choices__item--disabled {
            display: none;
        }

        /* Radio and Checkbox */
        .pb-form-radio,
        .pb-form-checkbox {
            -webkit-appearance: none !important;
            appearance: none !important;
            background-color: transparent !important;
            margin: 0 !important;
            --tw-ring-color: transparent !important;
            transform: translateY(-0.075em);
            border-color: rgba(var(--pb-c-input-border-normal), 1);
            @apply pb-w-6 pb-h-6 pb-border-[1px] focus:pb-ring-1 focus:pb-ring-offset-1 focus:pb-shadow-none;

            display: grid;
            place-content: center;
        }

        /* Radio */
        .pb-form-radio {
            border-radius: 200px;

            &::before {
                content: "" !important;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                transform: scale(0);
                /* transition: 120ms transform ease-in-out; */
                background-color: rgba(var(--pb-c-input-btn-background-normal), 1) !important;
            }

            &:checked::before {
                transform: scale(1);
            }

            /* Active state */
            &:active::before {
                /* background-color: rgba(var(--pb-c-input-active), 1); */
                border-color: rgba(var(--pb-c-input-border-active), 1) !important;
            }

            /* Active Checked state */
            &:checked:active::before {
                border-color: rgba(var(--pb-c-input-border-active), 1) !important;
                background-color: rgba(var(--pb-c-input-background-active), 1) !important;
            }

            /* Hover state */
            &:hover {
                border-color: rgba(var(--pb-c-input-border-hover), 1) !important;
                /* background-color: rgba(var(--pb-c-input-background-hover), 1)!important; */
            }

            &:checked:hover::before {
                border-color: rgba(var(--pb-c-input-border-hover), 1) !important;
                background-color: rgba(var(--pb-c-input-background-hover), 1) !important;
            }

            /* Disabled state */
            &:disabled::before {
                background-color: rgba(var(--pb-c-input-disabled), 1)!important;
                border-color: rgba(var(--pb-c-input-border-disabled), 1)!important;
            }

            /* Invalid state */
            &:invalid::before {
                border-color: rgba(var(--pb-c-input-border-error), 1) !important;
                border-width: 2px !important;
            }
        }

        /* Checkbox */
        .pb-form-checkbox {
            border-radius: 4px;

            /* Indeterminate state */
            &:indeterminate::before {
                content: "\e94d" !important;
                font-family: 'pb-icons' !important;
                width: 32px;
                height: 32px;
                font-size: 24px;
                font-weight: bold;
                border-radius: 4px;
                transform: scale(1);
                padding-left: 4px !important;
                background-color: rgba(var(--pb-c-input-btn-background-normal), 1) !important;
                color: rgba(var(--pb-c-input-btn-normal), 1) !important;
            }

            &::before {
                content: "\e95a" !important;
                font-family: 'pb-icons' !important;
                width: 32px;
                height: 32px;
                font-size: 24px;
                font-weight: bold;
                border-radius: 4px;
                transform: scale(0);
                padding-left: 4px !important;
                padding-top: 4px !important;
                background-color: rgba(var(--pb-c-input-btn-background-normal), 1) !important;
                color: rgba(var(--pb-c-input-btn-normal), 1) !important;
            }

            &:checked::before {
                transform: scale(1);
            }

            /* Active state */
            &:active::before {
                border-color: rgba(var(--pb-c-input-border-active), 1) !important;
                /* background-color: rgba(var(--pb-c-input-background-active), 1)!important; */
            }

            /* Active Checked state */
            &:checked:active::before {
                background-color: rgba(var(--pb-c-input-background-active), 1) !important;
                border-color: rgba(var(--pb-c-input-border-active), 1) !important;
            }

            /* Hover state */
            &:hover {
                /* background-color: rgba(var(--pb-c-input-background-hover), 1)!important; */
                border-color: rgba(var(--pb-c-input-border-hover), 1) !important;
            }

            /* Hover Checked state */
            &:checked:hover::before {
                background-color: rgba(var(--pb-c-input-background-hover), 1) !important;
                border-color: rgba(var(--pb-c-input-border-hover), 1) !important;
            }

            /* Disabled state */
            &:disabled::before {
                /* color: rgba(var(--pb-c-input-btn-normal), 1)!important; */
                background-color: rgba(var(--pb-c-input-background-disabled), 1) !important;
                border-color: rgba(var(--pb-c-input-border-disabled), 1) !important;
            }

            &:checked:disabled::before {
                background-color: rgba(var(--pb-c-input-background-disabled), 1) !important;
                border-color: rgba(var(--pb-c-input-border-disabled), 1) !important;
            }

            /* Invalid state */
            &:invalid::before {
                border-color: rgba(var(--pb-c-input-border-error), 1) !important;
                border-width: 2px !important;
            }
        }

        /* Datepicker css */
        input.pb-form-input.pb-form-datepicker {
            @apply pb-rounded-sm pb-relative pb-cursor-pointer;
            padding-left: 1rem !important;

            ~i {
                @apply pb-text-i-24 pb-absolute pb-top-2.5 pb-right-4;
                color: rgba(var(--pb-c-input-normal), 1);
            }
        }
        
        /* Large size */
        label.pb-form--large,
        p.pb-form--large {
            @apply pb-text-md;
        }

        input.pb-form--large {
            ~label,
            >span {
                @apply pb-text-lg;
            }
             
        }

        /* p.pb-form--large {
            @apply pb-text-sm;
        } */
        .pb-form-input.pb-form-datepicker.pb-form--large{
            ~i{
                @apply pb-text-i-32;
                top: 20px;
            }
        }

        .pb-form-input.pb-form--large {
            padding: 20px 16px 20px 16px !important;
            @apply pb-text-lg;

            ~span {
                @apply pb-text-i-32;
                top: 22px;
            }

            ~a>i {
                @apply pb-text-i-32;
                top: 24px;
            }
        }
        .pb-form-textarea.pb-form--large {
            @apply pb-text-lg pb-py-2 pb-px-4;
        }

        input[type=search].pb-form-input.pb-form--large {
            padding: 20px 56px 20px 16px !important;
        }

        /* Large size On Invalid/Error state */
        .pb-form-input.pb-form--large,
        .pb-form-textarea.pb-form--large,
        .pb-form-select.pb-form--large,
        .pb-form-multiselect.pb-form--large {
            &:invalid {
                border-width: 3px !important;

                ~p, ~p.pb-error-description {
                    color: rgba(var(--pb-text-error), 1);
                }
            }
            ~p.pb-error-description {
                color: rgba(var(--pb-text-error), 1);
            }
        }

        /* Large size Radio and Checkbox */
        .pb-form-radio.pb-form--large,
        .pb-form-checkbox.pb-form--large {
            // @apply !pb-w-9 !pb-h-9;
            width: 3.5rem !important;
            height: 3.5rem !important;
        }

        .pb-form-radio.pb-form--large {
            &::before {
                width: 38px !important;
                height: 38px !important;
            }

            &:invalid::before {
                border-width: 3px !important;
            }
        }

        .pb-form-checkbox.pb-form--large {
            &::before {
                width: 56px !important;
                height: 56px !important;
                font-size: 32px !important;
                padding-left: 13px !important;
                padding-top: 15px !important;
            }

            &:invalid::before {
                border-width: 3px !important;
            }
        }

        .two-switch-toggle,.three-switch-toggle
        {
          position: relative;
          min-height:68px;
          display:flex;
          justify-content:space-around;
          background:rgba(var(--pb-c-two-toggle-bg), 1);
          border-radius:999px;
          flex-wrap: nowrap;
          label{
            @apply lg:pb-text-lg pb-p-4 pb-z-10 pb-cursor-pointer pb-w-full pb-text-center pb-self-center;
            color: rgba(var(--pb-text-default), 1) !important;
          }

          input[type="radio"]{
            @apply pb-hidden pb-cursor-pointer;

            &:checked + label {
                @apply pb-font-bold
            }
            &:disabled + label {
                color: rgba(var(--pb-palette-grey-60), 1) !important;
            }
          }
        }

        [data-id='active-label-three-switch']{
          position: absolute;
          left:0;
          width: 34%;
          height: 100%;
          background: rgba(var(--pb-c-two-toggle-switch), 1);
          border: 1px solid rgba(var(--pb-c-two-toggle-switch), 1);
          border-radius: 999px;
          transition: .5s left ease;
          &.switch-esg{
            background: rgba(var(--pb-c-two-toggle-switch-esg), 1);
            border: 1px solid rgba(var(--pb-c-two-toggle-switch-esg), 1);
          }
        }

        .three-switch-toggle > input[type="radio"][data-id='switch3-radio1']:checked ~ [data-id='active-label-three-switch']{
          left:0;
        }

        .three-switch-toggle > input[type="radio"][data-id='switch3-radio2']:checked  ~ [data-id='active-label-three-switch']{
          left:33%;
        }

        .three-switch-toggle > input[type="radio"][data-id='switch3-radio3']:checked ~ [data-id='active-label-three-switch'] {
          left:66%;
        }

        .two-switch-toggle > [data-id='active-label-two-switch']{
          position: absolute;
          left:0;
          width: 50%;
          height: 100%;
          background: rgba(var(--pb-c-two-toggle-switch), 1);
          border: 1px solid rgba(var(--pb-c-two-toggle-switch), 1);
          border-radius: 999px;
          transition: .5s left ease;
          &.switch-esg{
            background: rgba(var(--pb-c-two-toggle-switch-esg), 1);
            border: 1px solid rgba(var(--pb-c-two-toggle-switch-esg), 1);
          }
        }

        .two-switch-toggle > input[type="radio"][data-id='switch2-radio1']:checked ~ [data-id='active-label-two-switch']{
          left:0;
        }

        .two-switch-toggle > input[type="radio"][data-id='switch2-radio2']:checked ~ [data-id='active-label-two-switch']{
          left:50%;
        }
        
    }
}
/* Datepicker css */
.datepicker-mods {

    i {
        @apply pb-hidden;
    }

    .air-datepicker {
        @apply pb-gap-y-1 pb-border-none;
        box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);
        background-color: rgba(var(--pb-background-default), 1);
        .air-datepicker--navigation {

            .air-datepicker-nav {
                @apply pb-items-center pb-pt-5 pb-px-5 pb-border-none;

                .air-datepicker-nav--title {
                    @apply pb-text-sm pb-font-bold;
                    color: rgba(var(--pb-c-input-normal), 1);
                }

                .air-datepicker-nav--action svg {
                    @apply pb-hidden;
                }
                .air-datepicker-nav--action {
                    border: none;
                    width: 0;
                    &:hover {
                        background-color: none;
                    }
                }
                .air-datepicker-nav--action[data-action="prev"]::before {
                    content: '\e908';
                    font-family: 'pb-icons' !important;
                    font-style: normal;
                    padding-top: 1px;
                    @apply pb-w-5 pb-h-5 pb-icon-color-default;
                }
                .air-datepicker-nav--action[data-action="next"]::before {
                    content: '\e909';
                    font-family: 'pb-icons' !important;
                    font-style: normal;
                    padding-top: 1px;
                    @apply pb-w-5 pb-h-5 pb-icon-color-default;
                }
            }
        }

        .air-datepicker--content {
            @apply pb-pb-2 pb-px-4;

            .air-datepicker-body--day-name,
            .air-datepicker-body--cells.-days-,
            .air-datepicker-body--cells.-months-,
            .air-datepicker-body--cells.-years- {
                color: rgba(var(--pb-c-input-normal), 1);
                @apply pb-text-sm;
            }
            .air-datepicker-body--cells.-days-,
            .air-datepicker-body--cells.-months-,
            .air-datepicker-body--cells.-years- {
                .air-datepicker-cell {
                    border-radius: 0;
                }
                /* current day */
                .air-datepicker-cell.-current- {
                    color: rgba(var(--pb-c-input-normal), 1);
                    position: relative;

                    &.-day-::before {
                        content: '';
                        border: 3px solid rgba(var(--pb-icon-default), 1);
                        position: absolute;
                        left: 12px;
                        bottom: 2px;
                        
                    }
                    &:hover {
                        background-color: rgba(var(--pc-c-datepicker-day-selected), 1);
                        color: rgba(var(--pb-palette-white), 1);
                    }
                    &.-selected-{
                        color: rgba(var(--pb-palette-white), 1);
                        background-color: rgba(var(--pc-c-datepicker-day-selected), 1);
                        &:hover {
                            color: rgba(var(--pb-c-input-normal), 1);
                            background-color: rgba(var(--pc-c-datepicker-day-selected), 1);
                        }
                    }
                    /* &.-focus-{
                        &::before {
                            left: 10px;
                        }
                    } */

                }
                .air-datepicker-cell.-day-.-focus- {
                    border: 2px solid rgba(var(--pb-c-input-border-focus), 1);
                }
                /* -focus- class is hover state for the library */
                /* and in range */
                .air-datepicker-cell.-day-.-focus-:hover,
                .air-datepicker-cell.-day-.-in-range-,
                .air-datepicker-cell.-range-to {
                    background-color: rgba(var(--pc-c-datepicker-day-hover), 1);
                    color: rgba(var(--pb-c-input-normal), 1);
                    border:none;
                }
                
                /* Inactive (Disabled) */
                .air-datepicker-cell.-day-.-disabled- {
                    color: rgba(var(--pb-c-input-disabled), 1);
                }
                .air-datepicker-cell.-selected- {
                    /* color: rgba(var(--pb-c-input-normal), 1); */
                    background-color: rgba(var(--pc-c-datepicker-day-selected), 1);

                    &:hover {
                        color: rgba(var(--pb-palette-white), 1);
                        background-color: rgba(var(--pc-c-datepicker-day-selected), 1);
                    }
                }
            }
        }
    }
}

/* Choices.js for dropdowns */
div.choices {
    border-color: rgba(var(--pb-c-input-border-normal), 1);
    background-color: rgba(var(--pb-background-default), 1);
    @apply focus:pb-ring-1 focus:pb-ring-offset-1 focus:pb-shadow-none pb-mb-0;

    /* Focused state */
    &:focus {
        --tw-ring-color: rgba(var(--pb-c-input-border-focus), 1);
        margin:0px 2px;
    }

    /* Active state */
    &.is-open {
        .choices__inner {
            border-color: rgba(var(--pb-c-input-border-active), 1);
            --tw-ring-color: transparent;
        }
    }
    /* Disabled state */
    &.is-disabled {
        border-color: rgba(var(--pb-c-input-border-disabled), 1);
        --tw-ring-color: transparent;

        .choices__list--single .choices__item,
        .choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
            color: rgba(var(--pb-c-input-disabled), 1);
        }
    }

    .choices__inner {
        background-color: rgba(var(--pb-background-default), 1);
        color: rgba(var(--pb-c-input-normal), 1);
        @apply pb-border;
        padding : 8px 16px !important;
        /* Hover state */
        &:hover {
            border-color: rgba(var(--pb-c-input-border-hover), 1);
        }

        .choices__placeholder {
            opacity: 1;
        }
    }


    .choices__list--single .choices__item,
    .choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
        @apply pb-text-md;

    }
    .choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
        background-color: inherit;
        
    }
    .choices__list--dropdown {

        z-index: 51;
        background-color: rgba(var(--pb-background-default), 1);
        border: none;
        box-shadow: 0px 10px 20px 0px rgba(30, 30, 30, 0.10);
        
        .choices__item:hover{
            background: rgba(var(--pb-choices-highlight), 1) !important;
        }
        
        .choices__placeholder[aria-disabled="true"] {
            display: none;
        }

        .choices__item {
            padding-right: 8px;
        }
    }

    /* Large size */
    &.pb-form--large {
        .choices__inner {
            .choices__list--single .choices__item,
            .choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
                @apply pb-text-lg pb-py-2;
            }
        }

        &[data-type*=select-one]::after,
        &[data-type*=select-one].is-focused.is-open::after {
            font-size: 32px;
            right: 46px!important;
            top: 24px!important;
        }
    }

    &.pb-menu-dropdown{

        margin-bottom: 0px;
        background-color: rgba(var(--pb-m-menu-background), 0);
        border:none;

        .choices__inner{
            background-color: rgba(var(--pb-m-menu-background), 0);
            border:none;
            padding-left:0px !important;
        }

        .choices__list.choices__list--dropdown{
            width: 320px;
        }

    }
}


div.choices[data-type*=select-one]::after {
    content: "\e949" !important;
    font-family: 'pb-icons' !important;
    font-style: normal;
    text-transform: none;
    font-size: 24px;
    border: none !important;
    position: absolute;
    right: 30px;
    top: 25%;
    margin-top: 0px !important;
    color: rgba(var(--pb-icon-default), 1);
}

div.choices[data-type*=select-one].is-focused.is-open::after {
    content: "\e948" !important;
    font-family: 'pb-icons' !important;
    font-style: normal;
    text-transform: none;
    font-size: 24px;
    border: none !important;
    position: absolute;
    right: 30px !important;
    top: 25% !important;
    margin-top: 0px !important;
    color: rgba(var(--pb-icon-default), 1);
}

html[data-energy-save="true"] {
    form {
        .pb-form-range-yellow,
        .pb-form-range-brand,
        .pb-form-range-esg {
            background: linear-gradient(to right, #557475 0%, #557475 40%, #fff 40%, #fff 100%);
            
            &::-webkit-slider-thumb {
                background: #557475;
            }
        }

    }

    .choices[data-type*=select-one] .choices__input{
        background-color: rgba(var(--pb-palette-green-70),1);
    }
}

html[data-energy-save="false"][data-sector="private"]{

    form .two-switch-toggle, 
    form .three-switch-toggle{
            
        input[type="radio"]:checked + label{
            color: rgba(var(--pb-palette-black), 1) !important;
        }
    }
}


.grecaptcha-badge{
    z-index: 20;
}