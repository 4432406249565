.pb-tool-tip{
    @apply pb-flex pb-justify-start pb-items-center pb-p-4 pb-text-md-m lg:pb-text-md ;
    
    &.pb-large{
        @apply pb-min-w-[350px];
    }

    background-color: rgba(var(--pb-c-tool-tip-bg), 1);
    color: rgba(var(--pb-c-tool-tip-txt), 1);

    &:after{
        border-bottom-width: 18px;
        border-color: transparent;
        border-left-width: 14px;
        border-right-width: 0;
        bottom: -17px;
        content: "";
        height: 0;
        left: 0px;
        position: absolute;
        transform: rotate(180deg);
        width: 20px;
        z-index: 2;
        border-bottom-color: rgba(var(--pb-c-tool-tip-bg), 1);
    }
}

.pb-tool-tip.pb-tool-tip-left{
    &:after{
        border-right-width: 14px;
        border-left-width: 0;
        right: 0px !important;
        left: auto;
    }
}


.pb-tool-tip-action {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.pb-show-tooltip {
    opacity: 1;
    visibility: visible;
    animation: fancyFadeInOut 2s ease-in-out forwards;
}

@keyframes fancyFadeInOut {
    0% {
        opacity: 0;
        transform: translateY(-10px) scale(0.9) rotate(-10deg);
    }
    20% {
        opacity: 1;
        transform: translateY(0) scale(1.1) rotate(0deg);
    }
    40%, 60% {
        opacity: 1;
        transform: translateY(0) scale(1) rotate(0deg);
    }
    80% {
        opacity: 1;
        transform: translateY(0) scale(1.1) rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: translateY(-10px) scale(0.9) rotate(10deg);
    }
}
